import RuleTable from "components/rules";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearRedux as clearReduxRole } from "redux/slice/roleTableSlice";

export default function Rules() {
  const dispatch = useDispatch();

  // Use the `useEffect` hook to clear Redux state when the component mounts.
  useEffect(() => {
    // Dispatch the action to clear Redux state for the RoleTable slice.
    dispatch(clearReduxRole());
  }, []);

  // Render the RoleTable component within a div.
  return (
    <div>
      <RuleTable />
    </div>
  );
}
