import { Empty, Input, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { ShipmentTableProps } from "./types";
import SearchAndFilter from "../SearchAndFilter";
import { putApi } from "redux/apis";
import { toastText } from "utils/utils";
import moment from "moment";
import { SALES_ORDER_STATUS } from "utils/shipment";
import EditRecordModal from "components/Shipping/EditShippingRecords";
import { SortSvgBottom, SortSvgTop } from "utils/svgs";
import { useNavigate } from "react-router-dom";

const { Column } = Table;

const CustomEmptyState = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      <div className="text-gray-500 text-lg">
        Please select a customer to view sales orders
      </div>
    }
  />
);

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const SalesOrderTable = (props: ShipmentTableProps) => {
  const {
    performSearchHandler,
    searchValue,
    salesOrderData,
    onCustomerChange,
    fetchSalesOrder,
    selectedCustomer,
    isLoading,
    isDefaultCustomer,
    defaultCustomer,
    performSortHandler,
    packageId,
    handleCancel,
    fetchItems,
  } = props;

  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (keys: React.Key[]) => {
  //     setSelectedRowKeys(keys as string[]);
  //   },
  // };
  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, pageInfo.current);
  };
  const [notes, setNotes] = useState<{ [key: string]: string }>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPackageId, setNewPackageId] = useState("");
  const isRowSelectionDisabled = selectedRowKeys.some((id) => {
    const order = salesOrderData.find((order) => order.id === id);
    return order?.status === "Shipped"; // Check if the status is "shipped"
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: React.Key[]) => {
      if (!isRowSelectionDisabled) {
        setSelectedRowKeys(keys as string[]);
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === "Shipped" || record.status === "UnderProcess", // Disable checkbox if status is "shipped"
    }),
  };

  const handleNoteChange = (id: string, value: string) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [id]: value, // This will store the new note or empty string
    }));
  };

  const handleReadyToShip = async () => {
    if (!selectedRowKeys.length) return;

    const data = {
      salesOrders: selectedRowKeys.map((id) => ({
        id,
        note: notes[id] || "", // If note is empty, send an empty string instead of null
      })),
    };

    try {
      let response;
      if (!isDefaultCustomer && !packageId) {
        response = await putApi("/sales-order/bulk-update", data);
        setNewPackageId(response.data.data?.packaging?.id);
        setIsModalOpen(true);
      } else {
        response = await putApi("/sales-order/update-packaging-sales-order", {
          packagingId: packageId,
          ...data,
        });
        fetchItems(packageId);
      }

      const message =
        response?.data?.message || "Sales order shipped successfully.";
      toastText(message, "success");
    } catch (error) {
      toastText("Something went wrong while shipping sales order.", "error");
    } finally {
      setSelectedRowKeys([]);
      setNotes({}); // Clear notes
      if (isDefaultCustomer) {
        handleCancel();
      }
      fetchSalesOrder();
    }
  };

  const handleEditRecordCancel = () => {
    setIsModalOpen(false);
    setNewPackageId("");
    navigate("/shipping");
  };

  useEffect(() => {
    if (salesOrderData) {
      const initialNotes = salesOrderData.reduce(
        (acc, order) => ({
          ...acc,
          [order.id]: order.notes || "",
        }),
        {}
      );
      setNotes(initialNotes);
    }
  }, [salesOrderData]);

  return (
    <div className={styles["shipment-table"]}>
      <div className={styles["shipment-table__wrapper"]}>
        <SearchAndFilter
          performSearchHandler={performSearchHandler}
          searchValue={searchValue}
          shipData={handleReadyToShip}
          isButtonDisabled={!selectedRowKeys.length}
          onCustomerChange={onCustomerChange}
          isDefaultCustomer={isDefaultCustomer}
          defaultCustomer={defaultCustomer}
        />
        {!selectedCustomer ? (
          <CustomEmptyState />
        ) : (
          <Table
            dataSource={salesOrderData}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ y: "calc(100vh - 300px)" }}
            className="table-global"
            rowSelection={rowSelection} // Integrating row selection
            loading={isLoading}
            onChange={tableChangeHandler}
          >
            <Column
              title="Sales Order"
              dataIndex="salesOrder"
              key="salesOrder"
              width={isDefaultCustomer ? "40%" : "10%"}
              className="bg-white"
            />
            <Column
              title="Date"
              dataIndex="salesOrderDate"
              key="salesOrderDate"
              width={isDefaultCustomer ? "30%" : "10%"}
              className="bg-white"
              sorter={(a, b) => {
                const dateA = new Date(a.salesOrderDate).getTime();
                const dateB = new Date(b.salesOrderDate).getTime();
                return dateA - dateB; // Sort dates in ascending order
              }}
              sortDirections={["ascend", "descend", "ascend"]}
              // eslint-disable-next-line react/jsx-no-undef
              sortIcon={(data) => <SortingIcon data={data} />}
              render={(text: string) =>
                text ? moment(text).format("MM/DD/YYYY") : "-"
              }
            />
            {!isDefaultCustomer && (
              <>
                <Column
                  title="Shipping Method"
                  dataIndex="shippingMethod"
                  key="shippingMethod"
                  width="15%"
                  className="bg-white"
                  render={(text) => (text ? text : "-")}
                />
                <Column
                  title="Shipping Address"
                  key="shippingAddress"
                  width="25%"
                  className="bg-white"
                  render={(text, record) => {
                    const { shippingAddress } = record;
                    if (
                      !shippingAddress?.addr1 &&
                      !shippingAddress?.addr2 &&
                      !shippingAddress?.city
                    ) {
                      return <div>-</div>; // Display hyphen if no address
                    }
                    return (
                      <div>
                        {shippingAddress?.addr1 && (
                          <div>{shippingAddress.addr1}</div>
                        )}
                        {shippingAddress?.addr2 && (
                          <div>{shippingAddress.addr2}</div>
                        )}
                        {shippingAddress?.city && (
                          <div>
                            {shippingAddress.city}, {shippingAddress.state}{" "}
                            {shippingAddress.postalCode}
                          </div>
                        )}
                        {shippingAddress?.country && (
                          <div>{shippingAddress.country}</div>
                        )}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Notes"
                  dataIndex="notes"
                  key="notes"
                  width="20%"
                  className="bg-white"
                  render={(text, record) => (
                    <Input
                      size="large"
                      placeholder="Enter note"
                      value={notes[record.id] || ""} // Use only notes state; no fallback to database value
                      onChange={(e) =>
                        handleNoteChange(record.id, e.target.value)
                      } // Handle note changes
                      disabled={record.status !== "Pending"} // Disable input if status is "Shipped"
                    />
                  )}
                />
              </>
            )}
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              width={isDefaultCustomer ? "30%" : "16%"}
              className="bg-white"
              // render={(status: string) => {
              //   const formattedStatus = status
              //     ?.replace(/_/g, " ")
              //     .toLowerCase()
              //     .replace(/\b\w/g, (char) => char.toUpperCase());

              //   return (
              //     <Tag
              //       color={
              //         SALES_ORDER_STATUS[
              //           status as keyof typeof SALES_ORDER_STATUS
              //         ]?.color
              //       }
              //     >
              //       {formattedStatus}
              //     </Tag>
              //   );
              // }}
              render={(status: string) => {
                // Splitting camel case or Pascal case into words and formatting
                const formattedStatus = status
                  ?.replace(/([a-z])([A-Z])/g, "$1 $2") // Splits camel case or Pascal case (e.g., UnderProcess -> Under Process)
                  .replace(/_/g, " ") // Replaces underscores with spaces
                  .toLowerCase() // Converts the string to lowercase
                  .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizes the first letter of each word

                return (
                  <Tag
                    color={
                      SALES_ORDER_STATUS[
                        status as keyof typeof SALES_ORDER_STATUS
                      ]?.color
                    }
                  >
                    {formattedStatus}
                  </Tag>
                );
              }}
            />
          </Table>
        )}
      </div>
      <EditRecordModal
        isModalOpen={isModalOpen}
        handleCancel={handleEditRecordCancel}
        selectedPackageId={newPackageId}
      />
    </div>
  );
};

export default SalesOrderTable;
