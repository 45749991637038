import { Sidebar } from "components/Global";
import { FORMDATA } from "constants/Data";
import styles from "./index.module.scss";
import { FC } from "react";
import { SettingsLayoutProps } from "./types";
import { useSelector } from "react-redux";
// Settings page layout component
const SettingsLayout: FC<SettingsLayoutProps> = (props) => {
  // Initialize variables and props
  const { settingsMenuItems } = FORMDATA;
  const { children, onSideBarChange, selectedSidebar } = props;
  const profileData = useSelector((state: any) => state?.userProfile?.data);

  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  // Filter settingsMenuItems based on the roleName
  const filteredSettingsMenuItems = settingsMenuItems.filter((item) => {
    if (roleName === "Service Center Employee") {
      // Hide "Integrations" and "Service Centers" for "Service Center Employee"
      return (
        item.key !== "integrations" &&
        item.key !== "service-centers" &&
        item.key !== "warranty-rules"
      );
    } else if (roleName === "Employee") {
      // Hide "Integrations", "Service Centers", and "Warranty Rules" for "Employee"
      return (
        item.key !== "integrations" && item.key !== "service-centers"
        // item.key !== "warranty-rules"
      );
    }

    // Show all items for other roles
    return true;
  });

  return (
    <div className={styles["settings-layout"]}>
      <div className={styles["settings-layout__wrapper"]}>
        <Sidebar
          items={filteredSettingsMenuItems}
          isGetSupportButton={true}
          handleSidebar={onSideBarChange}
          selectedSidebar={selectedSidebar as string}
        />
        <div className={styles["settings-layout__body"]}>{children}</div>
      </div>
    </div>
  );
};

export default SettingsLayout;
