import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import { FC } from "react";
import styles from "./index.module.scss";

const SearchAndFilter: FC<any> = (props) => {
  const { performSearchHandler, searchValue } = props;

  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[8, 8]} align="middle" justify="space-between">
          <Col>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Input
                className={styles["search-filter__search"]}
                placeholder="Search by sales order..."
                suffix={<SearchOutlined />}
                onChange={performSearchHandler}
                value={searchValue}
                size="large"
                style={{ width: "300px" }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
