import { TableActionHeader } from "components/Global";
import ItemConfigurationTable from "./ItemConfigurationTable";
import { ChangeEvent, useEffect, useState } from "react";
import { getApi, getApiExcel } from "redux/apis";
import { toastText } from "utils/utils";

const ItemConfiguration = () => {
  const [allItemsData, setAllItemsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [isExportLoading, setIsExportLoading] = useState(false);

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  // Handle sorting operation
  const performSortHandler = (type: string) => {
    if (type !== sortOrder) {
      setSortOrder(type);
      setSortOrder(type === "ascend" ? "asc" : type ? "desc" : "");
      setSortField("modelName");
    }
  };

  const exportItemHandler = async () => {
    try {
      setIsExportLoading(true);
      const response = await getApiExcel("/itemModel/export");
      console.log("response: ", response);
      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "items.xlsx"); // File name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toastText("Something went wrong in exporting models", "error");
    } finally {
      setIsExportLoading(false);
    }
  };

  const fetchAllItemModels = async () => {
    try {
      const query: any = {
        searchValue: searchValue,
        sortBy: sortField,
        sortOrder: sortOrder,
      };

      setIsLoading(true);

      try {
        const response = await getApi("/itemModel/getAllModels", query);
        setAllItemsData(response?.data?.data?.data);
        setTotalItems(response?.data?.data?.total)
      } catch (error) {
        console.log("error: ", error);
      }
    } catch (error: any) {
      if (error.response?.data?.error?.code !== 103) {
        toastText("Something went wrong in fetching item models", "error");
      } else {
        toastText(error.response?.data?.error?.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllItemModels();
  }, [searchValue, currentPage, sortOrder, sortField]);

  return (
    <>
      <div>
        <TableActionHeader title={"Items"}></TableActionHeader>

        <ItemConfigurationTable
          allItemData={allItemsData}
          totalItems={totalItems}
          isLoading={isLoading}
          performSearchHandler={performSearchHandler}
          performSortHandler={(type: any) => performSortHandler(type)}
          searchValue={searchValue}
          sortDirection={sortOrder}
          fetchAllItemModels={fetchAllItemModels}
          exportItemHandler={exportItemHandler}
          isExportLoading={isExportLoading}
        />
      </div>
    </>
  );
};

export default ItemConfiguration;
