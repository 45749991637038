import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, DatePicker, Button, Row, Col } from "antd";
import Title from "antd/es/typography/Title";
import { getApi, postApi, putApi } from "redux/apis";
import styles from "./index.module.scss";
import { FORMDATA } from "constants/Data";
import dayjs from "dayjs";
import MissingPartsModal from "../MissingSparePartModel";
import { toastText } from "utils/utils";

interface AddShipmentManuallyModalProps {
  isVisible: boolean;
  handleCancel: () => void;
  selectedShipment?: any;
  setSelectedShipment: React.Dispatch<React.SetStateAction<any>>;
  fetchAllShipmentDetails: any;
}

interface ServiceCenterOption {
  value: string | number;
  label: string;
}

const AddShipmentManuallyModal: React.FC<AddShipmentManuallyModalProps> = ({
  isVisible,
  handleCancel,
  selectedShipment,
  setSelectedShipment,
  fetchAllShipmentDetails,
}) => {
  const { shipmentFields } = FORMDATA;
  const [form] = Form.useForm();

  const [showMissingParts, setShowMissingParts] = useState(false);
  const [missingParts, setMissingParts] = useState([]);

  const [serviceCenters, setServiceCenters] = useState<ServiceCenterOption[]>(
    []
  );
  const [items, setItems] = useState<any[]>([]);

  const [statusOptions, setStatusOptions] = useState([
    { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: false },
    { value: "SHIPPED", label: "Shipped", disabled: true },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  // Effect to set form values when selectedShipment changes
  useEffect(() => {
    // Find service cemter id from service center name

    if (selectedShipment && serviceCenters) {
      const serviceCenterId = serviceCenters?.find(
        (center) => center.label === selectedShipment.serviceCenter
      )?.value;
      // Map the keys from the selected shipment to form fields
      const formValues = {
        serviceCenterName: serviceCenterId,
        salesOrder: selectedShipment.salesOrderNumber,
        deliveryDate: selectedShipment.deliveryDate
          ? dayjs(selectedShipment.deliveryDate)
          : null,
        itemNumber: selectedShipment.itemNumber,
        itemDescription: selectedShipment.itemDescription || null,
        status: selectedShipment.status,
        quantity: selectedShipment.quantity.toString(),
      };
      form.setFieldsValue(formValues);

      setStatusOptions(
        selectedShipment.deliveryDate
          ? [
              {
                value: "READY_TO_SHIP",
                label: "Ready to Ship",
                disabled: true,
              },
              { value: "SHIPPED", label: "Shipped", disabled: false },
            ]
          : [
              {
                value: "READY_TO_SHIP",
                label: "Ready to Ship",
                disabled: false,
              },
              { value: "SHIPPED", label: "Shipped", disabled: true },
            ]
      );
      form.setFieldsValue({ status: "READY_TO_SHIP" });
    } else {
      // Reset form when no shipment is selected
      form.resetFields();
      setStatusOptions([
        { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: false },
        { value: "SHIPPED", label: "Shipped", disabled: true },
      ]);
    }
  }, [selectedShipment, form]);

  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        const response = await getApi("/serviceCenter/shipment-dropdown");
        if (response.data.success) {
          setServiceCenters(
            response.data.data.map((center: any) => ({
              value: center.id,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch service centers:", error);
      }
    };

    const fetchItems = async () => {
      try {
        const response = await getApi("/shipment/getProducts?type=true");
        if (response.data.data.length > 0) {
          setItems(
            response.data.data.map((center: any) => ({
              value: center.name,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    fetchServiceCenters();
    fetchItems();
  }, []);

  const onFieldsChange = () => {
    const deliveryDate = form.getFieldValue("deliveryDate");

    if (deliveryDate) {
      setStatusOptions([
        { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: true },
        { value: "SHIPPED", label: "Shipped", disabled: false },
      ]);
      form.setFieldsValue({ status: "SHIPPED" });
    } else {
      setStatusOptions([
        { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: false },
        { value: "SHIPPED", label: "Shipped", disabled: true },
      ]);
      form.setFieldsValue({ status: "READY_TO_SHIP" });
    }
  };

  const onFinish = async (values: any) => {
    try {
      // Prepare the payload with mapped keys
      const payload = {
        serviceCenterId: values.serviceCenterName,
        salesOrderNumber: values.salesOrder,
        deliveryDate: values.deliveryDate
          ? dayjs(values.deliveryDate).format("YYYY-MM-DD")
          : null,
        itemNumber: values.itemNumber,
        description: values.itemDescription,
        status: values.status,
        quantity: parseInt(values.quantity, 10),
      };

      let response;
      setIsLoading(true);
      if (selectedShipment) {
        response = await putApi(
          `/shipment/update-shipment/${selectedShipment.id}`,
          payload
        );
      } else {
        response = await postApi("/shipment/create-shipment", payload);
      }

      const message =
        response?.data?.message || "Shipment created successfully.";

      toastText(message, "success");
    } catch (error: any) {
      // Handle the error response structure
      if (error.response?.data?.error?.additionalInfo?.missingParts) {
        // Show missing parts modal
        setMissingParts(error.response.data.error.additionalInfo.missingParts);
        setShowMissingParts(true);
      } else {
        toastText("Something went wrong while creating shipment", "error");
        // message.error(
        //   error.response?.data?.message ||
        //     (selectedShipment
        //       ? "Failed to update shipment"
        //       : "Failed to add shipment")
        // );
      }
    } finally {
      setIsLoading(false);
      form.resetFields();
      handleCancel();
      fetchAllShipmentDetails();
      setSelectedShipment(null);
    }
  };

  return (
    <>
      <Modal
        open={isVisible}
        onCancel={() => {
          handleCancel();
          setSelectedShipment(null);
          form.resetFields();
        }}
        footer={null}
        maskClosable={true}
        closable={false}
        width={800}
        className={styles["add-shipment-modal"]}
      >
        <div style={{ padding: "10px" }}>
          <Title level={4}>
            {selectedShipment
              ? "Edit Shipment Manually"
              : "Add Shipment Manually"}
          </Title>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            initialValues={{
              serviceCenterName: null,
              salesOrder: "",
              itemName: null,
              itemDescription: "",
              quantity: "",
              deliveryDate: null,
              status: "READY_TO_SHIP",
            }}
          >
            <Row gutter={24}>
              {shipmentFields.map((singleField: any, index: number) => {
                return (
                  <Col
                    span={singleField.id === "itemDescription" ? 24 : 12}
                    style={{ marginBottom: "16px" }}
                    key={index}
                  >
                    <label className={styles["side-drawer-form__role--label"]}>
                      {singleField.title}{" "}
                      {singleField?.required && (
                        <span
                          className="required-color"
                          style={{ marginLeft: "5px" }}
                        >
                          *
                        </span>
                      )}
                    </label>
                    {singleField.id === "itemNumber" && (
                      <Form.Item
                        name="itemNumber"
                        rules={[
                          {
                            required: true,
                            message: `Please select ${singleField.title}`,
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder={`Select ${singleField.title}`}
                          showSearch
                          optionFilterProp="label"
                          options={items}
                          // loading={!items.length}
                        />
                      </Form.Item>

                      // <Form.Item
                      //   name="itemName"
                      //   rules={[
                      //     {
                      //       required: true,
                      //       message: `Please enter ${singleField.title}`,
                      //     },
                      //   ]}
                      // >
                      //   <Input
                      //     size="large"
                      //     placeholder={`Enter ${singleField.title}`}
                      //   />
                      // </Form.Item>
                    )}
                    {singleField.id === "quantity" && (
                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            required: true,
                            message: `Please enter ${singleField.title}`,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          type="number"
                          placeholder={`Enter ${singleField.title}`}
                        />
                      </Form.Item>
                    )}

                    {singleField.id === "serviceCenterName" && (
                      <Form.Item
                        name="serviceCenterName"
                        rules={[
                          {
                            required: true,
                            message: `Please select ${singleField.title}`,
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder={`Select ${singleField.title}`}
                          options={serviceCenters}
                          showSearch
                          optionFilterProp="label"
                          filterOption={(
                            input: string,
                            option?: ServiceCenterOption
                          ) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // loading={!serviceCenters.length}
                        />
                      </Form.Item>
                    )}

                    {singleField.id === "salesOrder" && (
                      <Form.Item
                        name="salesOrder"
                        rules={[
                          {
                            required: true,
                            message: `Please enter ${singleField.title}`,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={`Enter ${singleField.title}`}
                        />
                      </Form.Item>
                    )}
                    {singleField.id === "itemDescription" && (
                      <Form.Item name="itemDescription">
                        <Input.TextArea
                          size="large"
                          placeholder={`Enter ${singleField.title}`}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                      </Form.Item>
                    )}

                    {singleField.id === "deliveryDate" && (
                      <Form.Item name="deliveryDate">
                        <DatePicker
                          placeholder={`Select ${singleField.title}`}
                          style={{ width: "100%" }}
                          size="large"
                          // disabledDate={(current) =>
                          //   current && current < moment().startOf("day")
                          // }
                          format="MM/DD/YYYY"
                        />
                      </Form.Item>
                    )}
                    {singleField.id === "status" && (
                      <Form.Item name="status">
                        <Select
                          placeholder={`Select ${singleField.title}`}
                          size="large"
                          options={statusOptions}
                        ></Select>
                      </Form.Item>
                    )}
                  </Col>
                );
              })}
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "20px",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="save-button"
                loading={isLoading}
              >
                {selectedShipment ? "Update" : "Save"}
              </Button>
              <Button
                size="large"
                htmlType="button"
                style={{
                  marginLeft: "10px",
                }}
                onClick={() => {
                  handleCancel();
                  setSelectedShipment(null);
                  form.resetFields();
                }}
                className="cancel-button"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      {missingParts?.length > 0 && (
        <MissingPartsModal
          isVisible={showMissingParts}
          handleClose={() => setShowMissingParts(false)}
          missingParts={missingParts}
        />
      )}
    </>
  );
};

export default AddShipmentManuallyModal;
