import { Form, Input } from "antd";
// import { FC, useState } from "react";
import styles from "./index.module.scss";
import { singleUserInputInputWithLabelAndSvgProps } from "./types";
import { phoneNumberFormatDisplay } from "helper/phoneNumberFormatter";
import { FC } from "react";
// import PasswordValidation from "../PasswordValidation";
// For create the dynamic input component
const InputWithLabelAndSvg: FC<singleUserInputInputWithLabelAndSvgProps> = (
  props
) => {
  // Inits
  const {
    singleUserInput,
    disabled,
    phoneNumber,
    setPhoneNumber,
    phoneError,
    setPhoneError,
    setIsPasswordValidate,
  } = props;

  // const [passwordValidations, setPasswordValidations] = useState<{
  //   minLength?: boolean;
  //   hasSpecialAndNumber?: boolean;
  // }>({});

  const phoneChangeHandler = (e: any) => {
    if (e.target.value === "") {
      setPhoneError("");
    }
    if (e.target.value.length !== 14) {
      setPhoneNumber(phoneNumberFormatDisplay(e.target.value));
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
      setPhoneNumber(phoneNumberFormatDisplay(e.target.value));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePasswordChange = (password: string) => {
    setIsPasswordValidate(true);

    // setPasswordValidations({ minLength: false, hasSpecialAndNumber: false });
    // if (password.length >= 8) {
    //   // setPasswordValidations({ minLength: true, hasSpecialAndNumber: false });
    //   // if (
    //   //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
    //   //     password
    //   //   )
    //   // ) {
    //   //   // setPasswordValidations({ minLength: true, hasSpecialAndNumber: true });
    //   //   setIsPasswordValidate(true);
    //   // } else {
    //   //   // setPasswordValidations({ minLength: true, hasSpecialAndNumber: false });
    //   //   setIsPasswordValidate(false);
    //   // }
    // } else {
    //   // setPasswordValidations({ minLength: false, hasSpecialAndNumber: false });
    //   setIsPasswordValidate(false);
    // }
  };
  // JSX
  return (
    <div className={styles["input-icon"]}>
      {singleUserInput.id !== "phone" ? (
        <>
          <div className={styles["input-icon__title"]}>
            {singleUserInput.svg && (
              <div className={styles["input-icon__title--svg"]}>
                {singleUserInput.svg}
              </div>
            )}
            <label className={styles["input-icon__title--label"]}>
              {singleUserInput.title}{" "}
              {singleUserInput?.required && (
                <span className="required-color">*</span>
              )}
            </label>
          </div>
          <div className={styles["input-icon__form"]}>
            <Form.Item
              name={singleUserInput.name}
              rules={singleUserInput.rules}
            >
              {singleUserInput.type === "text" &&
                singleUserInput.id !== "phone" && (
                  <Input
                    placeholder={singleUserInput.placeHolder}
                    size="large"
                    className={styles["input-icon__form--input"]}
                    type={singleUserInput.type}
                    disabled={disabled ? disabled : false}
                  />
                )}

              {singleUserInput.type === "password" && (
                <div>
                  <Input.Password
                    placeholder={singleUserInput.placeHolder}
                    size="large"
                    className={styles["input-icon__form--input"]}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                  />
                </div>
              )}
              {singleUserInput.type === "textArea" && (
                <Input.TextArea
                  className={styles["input-icon__form--input"]}
                  placeholder={singleUserInput.placeHolder}
                  disabled={disabled ? disabled : false}
                />
              )}
            </Form.Item>
            {/* {singleUserInput.id === "password" && (
              <div>
                <PasswordValidation
                  minLength={passwordValidations.minLength}
                  hasSpecialAndNumber={passwordValidations.hasSpecialAndNumber}
                />
              </div>
            )} */}
          </div>
        </>
      ) : (
        ``
      )}
      {singleUserInput.id === "phone" && (
        <>
          <div className={styles["input-icon__title"]}>
            {singleUserInput.svg && (
              <div className={styles["input-icon__title--svg"]}>
                {singleUserInput.svg}
              </div>
            )}
            {
              <label className={styles["input-icon__title--label"]}>
                {singleUserInput.title}{" "}
                {singleUserInput?.required && (
                  <span className="required-color">*</span>
                )}
              </label>
            }
          </div>
          <Form.Item rules={singleUserInput.rules}>
            <Input
              placeholder={singleUserInput.placeHolder}
              size="large"
              className={styles["input-icon__form--input"]}
              onChange={phoneChangeHandler}
              value={phoneNumber}
              maxLength={14}
            />
            <span className={styles["phoneError"]}>{phoneError}</span>
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default InputWithLabelAndSvg;
