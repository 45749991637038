import { Table, Tag, Button } from "antd";
import { useState } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { ShipmentTableProps } from "./types";
import moment from "moment";
import { SALES_ORDER_STATUS } from "utils/shipment";
import PackagingListModal from "../PackagingListModel";
import { DownloadOutlined } from "@ant-design/icons";
import { getApi, getApiExcelforPdf } from "redux/apis";
import { toastText } from "utils/utils";
import { invalidText } from "utils/utils";
import SearchAndFilter from "./SearchAndFilter";
import { SortSvgBottom, SortSvgTop } from "utils/svgs";

const { Column } = Table;

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
}

const CompletedSalesOrderTable = (props: ShipmentTableProps) => {
  const {
    salesOrderData,
    isLoading,
    searchValue,
    performSearchHandler,
    performSortHandler
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  console.log("selectedOrder: ", selectedOrder);
  const [packagingData, setPackagingData] = useState<any[]>([]);
  const [selectedPackageId, setSelectedPackageId] = useState<any>();
  const [loadingPaySlip, setLoadingPaySlip] = useState<Record<string, boolean>>(
    {}
  );

    const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
      performSortHandler &&
        performSortHandler(columnInfo.order, pageInfo.current);
    };

  const handleSalesOrderClick = async (
    salesOrder: string,
    packageId: string
  ) => {
    try {
      const response: any = await getApi(
        `/shipping/packaginglist?packageId=${packageId}`
      );
      const data = response.data.data;
      setPackagingData(Array.isArray(data.pallets) ? data.pallets : []);
      setSelectedOrder(salesOrder);
      setSelectedPackageId(packageId);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching packaging data:", error);
    }
  };

  const exportPdf = async (packageId: string) => {
    setLoadingPaySlip((prev) => ({ ...prev, [packageId]: true }));
    try {
      const response: any = await getApiExcelforPdf(
        `/packing-list/pdf?packageId=${packageId}`
      );

      if (!response || response.status !== 200) {
        throw new Error("Failed to fetch the PDF document.");
      }

      const fileName =
        moment(new Date()).format("MM-DD-YYYY_hh-mm-ss") + `Packing_Slip.pdf`;

      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });

      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      const errorMessage =
        err?.response?.data?.error?.message ||
        "Something went wrong in exporting pdf report.";
      if (err.response.status === 400) {
        toastText(
          "No report has been generated for this packagaing details",
          "error"
        );
      } else {
        toastText(errorMessage, "error");
      }
    } finally {
      setLoadingPaySlip((prev) => ({ ...prev, [packageId]: false }));
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedOrder(undefined);
  };

  const getRowSpan = (id: string, index: number | undefined) => {
    if (index === undefined) return 0;
    const groupedRows = salesOrderData.filter(
      (item: any) => item.packageId === id
    );
    const firstRowIndex = salesOrderData.findIndex(
      (item: any) => item.packageId === id
    );
    return index === firstRowIndex ? groupedRows.length : 0;
  };

  return (
    <div className={styles["shipment-table"]}>
      <div className={styles["shipment-table__wrapper"]}>
        <SearchAndFilter
          performSearchHandler={performSearchHandler}
          searchValue={searchValue}
        />
        <Table
          dataSource={salesOrderData}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ y: "calc(100vh - 350px)" }}
          className="table-global"
          loading={isLoading}
          bordered
          onChange={tableChangeHandler}
        >
          <Column
            title="Sales Order"
            dataIndex="salesOrder"
            key="salesOrder"
            width="10%"
            className="bg-white"
            render={(salesOrder: string, record: any) => {
              const relatedSalesOrders = salesOrderData
                .filter((item) => item.packageId === record.packageId)
                .map((item) => item.salesOrder)
                .join(", ");
              return (
                <a
                  onClick={() =>
                    handleSalesOrderClick(relatedSalesOrders, record.packageId)
                  }
                >
                  {relatedSalesOrders}
                </a>
              );
            }}
            onCell={(record: any, index: number | undefined) => ({
              rowSpan:
                index !== undefined ? getRowSpan(record.packageId, index) : 0,
            })}
          />
          <Column
            title="Date"
            dataIndex="date"
            key="date"
            width="10%"
            className="bg-white"
            render={(text: string) =>
              text ? moment(text).format("MM/DD/YYYY") : "-"
            }
          />
          <Column
            title="Shipping Method"
            dataIndex="shippingMethod"
            key="shippingMethod"
            width="15%"
            className="bg-white"
            render={(text: string) => (invalidText(text) ? "-" : text)}
          />
          <Column
            title="Notes"
            dataIndex="notes"
            key="notes"
            width="15%"
            className="bg-white"
            render={(text: string) => (invalidText(text) ? "-" : text)}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width="10%"
            className="bg-white"
            render={(_: any, record: any, index: number) => {
              const rowSpan = getRowSpan(record.packageId, index);
              const formattedStatus = record.status
                ?.replace(/_/g, " ")
                .toLowerCase()
                .replace(/\b\w/g, (char: any) => char.toUpperCase());
              return rowSpan > 0 ? (
                <>
                  <Tag
                    color={
                      SALES_ORDER_STATUS[
                        record.status as keyof typeof SALES_ORDER_STATUS
                      ]?.color
                    }
                  >
                    {formattedStatus}
                  </Tag>
                </>
              ) : null;
            }}
            onCell={(_: any, index: number | undefined) => ({
              rowSpan:
                index !== undefined
                  ? getRowSpan(salesOrderData[index].packageId, index)
                  : 0,
            })}
          />
          <Column
            title="Last Modified"
            dataIndex="updatedAt"
            key="updatedAt"
            width="15%"
            className="bg-white"
            sorter={(a, b) => {
              const dateA = new Date(a.salesOrderDate).getTime();
              const dateB = new Date(b.salesOrderDate).getTime();
              return dateA - dateB; // Sort dates in ascending order
            }}
            sortDirections={["ascend", "descend", "ascend"]}
            // eslint-disable-next-line react/jsx-no-undef
            sortIcon={(data) => <SortingIcon data={data} />}
            render={(text: string) =>
              text ? moment(text).format("MM/DD/YYYY") : "-"
            }
          />
          {/* New Column for Generate Pay Slip */}
          <Column
            title="Packing Slip"
            key="generatePaySlip"
            width="8%"
            className="bg-white"
            render={(_: any, record: any, index: number) => {
              const rowSpan = getRowSpan(record.packageId, index); // Use the same logic as "Status"
              return rowSpan > 0 ? (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => exportPdf(record.packageId)} // Pass packageId to exportPdf
                  loading={!!loadingPaySlip[record.packageId]}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} // Center the icon
                />
              ) : null; // Hide the button for rows that are spanned
            }}
            onCell={(_: any, index: number | undefined) => ({
              rowSpan:
                index !== undefined
                  ? getRowSpan(salesOrderData[index].packageId, index)
                  : 0,
            })}
          />
        </Table>
        <PackagingListModal
          isVisible={isModalVisible}
          packagingData={packagingData}
          onClose={handleModalClose}
          onGeneratePaySlip={exportPdf} // Use exportPdf directly
          packageId={selectedPackageId}
          loadingPaySlip={loadingPaySlip}
        />
      </div>
    </div>
  );
};

export default CompletedSalesOrderTable;
