import { SearchOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { FC } from "react";
import styles from "./index.module.scss";

const SearchAndFilter: FC<any> = (props) => {
  const {
    addItemData,
    performSearchHandler,
    searchValue,
    apiLoading,
    exportItemHandler,
    isExportLoading,
  } = props;

  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[8, 8]} align="middle" justify="space-between">
          <Col>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Input
                className={styles["search-filter__search"]}
                placeholder="Search by model name..."
                suffix={<SearchOutlined />}
                onChange={performSearchHandler}
                value={searchValue}
                size="large"
                style={{ width: "300px" }}
              />
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Tooltip title="Export excel file" placement="topLeft">
                <Button
                  type="primary"
                  className={styles["search-filter__markReceived"]}
                  onClick={exportItemHandler}
                  disabled={apiLoading || isExportLoading}
                  loading={isExportLoading}
                  icon={ <VerticalAlignBottomOutlined style={{fontSize:'20px'}}/>}
                  iconPosition="end"
                >
                  Export
                </Button>
              </Tooltip>
              <Tooltip
                title="All the changes in the table will be saved from here"
                placement="topLeft"
              >
                <Button
                  type="primary"
                  className={styles["search-filter__markReceived"]}
                  onClick={addItemData}
                  disabled={apiLoading || isExportLoading}
                  loading={apiLoading}
                >
                  Save
                  {/* {apiLoading ? <Spin size="small" /> : "Save"}
                   */}
                </Button>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
