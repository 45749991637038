import { createAsyncThunk } from "@reduxjs/toolkit";
import { postApi } from "redux/apis";

export const getDefaultMonthActionTable = createAsyncThunk(
  "warrantyRulesTable/getDefaultMonthWarrantyRulesTable",
  async (_, { rejectWithValue }) => {
    try {
      // Call POST API with the constructed body
      const response = await postApi(`/warranty-rules/default-month`);
      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
