import React from "react";
import { Modal, Table, Button, Tooltip, Popover } from "antd";
import Title from "antd/es/typography/Title";
import { MissingPartsModalProps } from "./types";
import styles from "./index.module.scss";

const MissingPartsModal: React.FC<MissingPartsModalProps> = ({
  isVisible,
  handleClose,
  missingParts,
}) => {
  const columns = [
    {
      title: "Service Center",
      dataIndex: "serviceCenter",
      key: "serviceCenter",
      width: "15%",
      className: "bg-white",
      render: (text: string) => {
        if (text && text.length > 18) {
          return (
            <Popover content={text} trigger="hover">
              <span>{text.slice(0, 18)}...</span>
            </Popover>
          );
        }
        return text;
      },
    },
    {
      title: (
        <Tooltip title="3nStar Sales Order">
          <span className={styles["ellipsis-title"]}>3nStar Sales Order</span>
        </Tooltip>
      ),
      dataIndex: "salesOrderNumber",
      key: "salesOrderNumber",
      width: "20%",
      className: "bg-white",
    },
    {
      title: "Spare Part Item",
      dataIndex: "itemName",
      width: "20%",
      key: "itemName",
      className: "bg-white",
    },
    {
      title: (
        <Tooltip title="Item Description">
          <span className={styles["ellipsis-title"]}>Item Description</span>
        </Tooltip>
      ),
      dataIndex: "description",
      key: "description",
      width: "30%",
      className: "bg-white",
      render: (text: string) => {
        if (text && text.length > 20) {
          return (
            <Popover content={text} trigger="hover">
              <span>{text.slice(0, 20)}...</span>
            </Popover>
          );
        }
        return text;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      className: "bg-white",
      width: "15%",
    },
  ];

  return (
    <Modal
      open={isVisible}
      onCancel={handleClose}
      footer={[
        <Button
          key="close"
          type="primary"
          onClick={handleClose}
          className={styles["close-button"]}
        >
          Close
        </Button>,
      ]}
      width={1000}
      className={styles["missing-parts-modal"]}
      maskClosable={false}
      destroyOnClose={true}
      // style={{ top: 20 }}
    >
      <div className={styles["modal-content"]}>
        <Title level={4} className={styles["modal-title"]}>
          Missing Spare Parts
        </Title>
        <div className={styles["table-container"]}>
          <Table
            dataSource={missingParts}
            columns={columns}
            rowKey="sparePartItem"
            pagination={false}
            //      className={styles["missing-parts-table"]}
            scroll={{ y: "calc(550px - 200px)" }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MissingPartsModal;
