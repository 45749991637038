import { useEffect, useState } from "react";
import { toastText } from "utils/utils";
import InventoryTable from "./InventoryTable";
import { InventoryModal } from "./AddInventoryModal";
import { TableActionHeader } from "components/Global";
import { AddSvg } from "utils/svgs";
import InventoryEditModal from "./InventoryEditModel";
import { getApi } from "redux/apis";
import { useSelector } from "react-redux";
import { InventoryProps } from "./types";
import dayjs from "dayjs";
const InventoryComponent = (props: InventoryProps) => {
  const { refetchCounts } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [allInventoryData, setAllInventoryData] = useState<any>([]);
  const [isAddInventoryModalOpen, setIsAddInventoryModalOpen] = useState(false);
  const [isEditInventoryModalOpen, setIsEditInventoryModalOpen] =
    useState(false);
  const [selectedInventory, setSelectedInventory] = useState<any>(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchFields] = useState([
    "serviceCenter",
    "salesOrderNumber",
    "itemNumber",
  ]);

  const [selectedServiceCenter, setSelectedServiceCenter] = useState(null);
  const [selectedSpareParts, setSelectedSpareParts] = useState([]);
  const [selectedSalesOrderNumbers, setSelectedSalesOrderNumbers] = useState(
    []
  );
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const isServiceCenter =
    profileData?.companyRoles[0]?.role?.roleName === "Service Center Employee";

  const isEmployee =
    profileData?.companyRoles[0]?.role?.roleName === "Employee";

  const isAdmin =
    profileData?.companyRoles[0]?.role?.roleName === "Company Admin";

  // const handleCancel = () => {
  //   setIsAddInventoryModalOpen(false);
  // };

  const showAddInventoryModal = () => {
    setIsAddInventoryModalOpen(true);
  };

  const showEditInventoryModal = (inventoryData: any) => {
    setSelectedInventory(inventoryData);
    setIsEditInventoryModalOpen(true);
  };

  const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
    setTotalRecords(pagination.total);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortOrder(
      sorter?.order === "ascend" ? "asc" : sorter?.order ? "desc" : ""
    );
    setSortField(sorter.field);
  };

  const performSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    setCurrentPage(1);
    fetchAllInventoryDetails({
      pageNumber: 1,
      pageSize,
      searchParams: { fields: searchFields, value: value.trim() },
    });
  };

  const fetchAllInventoryDetails = async (params?: any) => {
    try {
      const query = {
        page: params?.pageNumber || currentPage,
        pageSize: params?.pageSize || pageSize,
        sortBy: sortField,
        sortOrder: sortOrder,
        searchValue: params?.searchParams?.value || "",
        serviceCenterId: params?.serviceCenterId,
        itemNumber: params?.itemNumber,
        salesOrderNumber: params?.salesOrderNumbers,
        startDate: params?.startDate,
        endDate: params?.endDate,
      };

      setIsLoading(true);
      const response = await getApi("/inventory", query);
      const { total, data: inventoryDetails } = response.data.data;

      setAllInventoryData(inventoryDetails);
      await refetchCounts();
      setTotalRecords(total);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message || "Failed to fetch inventory";
      const errorCode = error.response?.data?.error?.code;

      if (errorCode === 403) {
        toastText("You don't have permission to view inventory", "error");
      } else if (errorCode === 404) {
        toastText("No inventory found", "warning");
      } else {
        toastText(errorMessage, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOk = () => {
    fetchAllInventoryDetails({
      pageNumber: 1,
      pageSize,
      serviceCenterId: selectedServiceCenter,
      itemNumber: selectedSpareParts,
      salesOrderNumbers: selectedSalesOrderNumbers,
      startDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : null,
      endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : null,
    });
  };
  const handleServiceCenterChange = (data: any) => {
    setSelectedServiceCenter(data);
  };
  const handleSparePartsChange = (data: any) => {
    setSelectedSpareParts(data);
  };

  const handleFromDateChange = (date: any) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: any) => {
    setToDate(date);
  };
  const handleSalesOrderNumberChange = (data: any) => {
    setSelectedSalesOrderNumbers(data);
  };
  useEffect(() => {
    fetchAllInventoryDetails();
  }, [currentPage, pageSize, sortField, sortOrder]);

  return (
    <>
      <div>
        <TableActionHeader title={"Inventory"}>
          {!isEmployee && (
            <div>
              <button className={`btn-blue`} onClick={showAddInventoryModal}>
                <AddSvg />
                <p>Add Inventory</p>
              </button>
            </div>
          )}
        </TableActionHeader>

        <InventoryTable
          allInventoryData={allInventoryData}
          isLoading={isLoading}
          onEdit={showEditInventoryModal}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
          tableChangeHandler={tableChangeHandler}
          isServiceCenter={isServiceCenter}
          isEmployee={isEmployee}
          searchValue={searchValue}
          performSearchHandler={performSearchHandler}
          searchFields={searchFields}
          handleServiceCenterChange={handleServiceCenterChange}
          selectedServiceCenter={selectedServiceCenter}
          handleSparePartsChange={handleSparePartsChange}
          selectedSpareParts={selectedSpareParts}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          fromDate={fromDate}
          toDate={toDate}
          handleSalesOrderNumberChange={handleSalesOrderNumberChange}
          selectedSalesOrderNumbers={selectedSalesOrderNumbers}
          handleOk={handleOk}
          fetchInventory={fetchAllInventoryDetails}
          isAdmin={isAdmin}
          sortDirection={sortOrder}
        />

        {isAddInventoryModalOpen ? (
          <InventoryModal
            open={isAddInventoryModalOpen}
            onCancel={() => {
              setIsAddInventoryModalOpen(false);
            }}
            fetchInventory={fetchAllInventoryDetails}
          />
        ) : null}

        {isEditInventoryModalOpen && (
          <InventoryEditModal
            open={isEditInventoryModalOpen}
            onCancel={() => setIsEditInventoryModalOpen(false)}
            inventoryData={selectedInventory}
            fetchInventory={fetchAllInventoryDetails}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </>
  );
};

export default InventoryComponent;
