import { Button, Form, Input, Row, Col, InputNumber, DatePicker } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { SideDrawerBodyProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import {
  addWarrantyRulesActionTable,
  editWarrantyRulesActionTable,
} from "redux/action/warrantyRulesActions";
import { userCheck } from "redux/slice/userCheckSlice";
import dayjs from "dayjs";

const AddRoleBody: FC<SideDrawerBodyProps> = (props) => {
  const { closeDrawerByAnimation, editSelectedRule } = props;

  const [form] = Form.useForm();
  const [saveAndAddLoader, setSaveAndAddLoader] = useState(false);
  const { isLoading } = useSelector((state: any) => state?.roleTable);

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    form.setFieldsValue({
      effectiveDate: dayjs(form.getFieldValue("effectiveDate")),
    });
  }, [form]);

  const onFinish = (values: any, buttonType = "save") => {
    if (editSelectedRule) {
      const data = {
        id: editSelectedRule?.id,
        comments: values?.comments,
        effectiveDate: values.effectiveDate,
        productCategory: values?.productCategory, // Add productCategory
        countryISOCode: values?.countryIsoCode, // Add countryIsoCode (assuming typo in the field name)
        customer: values?.customer, // Add customer
        product: values?.product, // Add product
        warrantyMonth: String(values?.warrantyMonth), // Add warrantyMonth
      };
      dispatch(editWarrantyRulesActionTable(data)).then(async (res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        } else {
          closeDrawerByAnimation();
        }
      });
    } else {
      buttonType === "SaveAndAdd" && setSaveAndAddLoader(true);
      dispatch(addWarrantyRulesActionTable(values)).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        }
        if (buttonType === "SaveAndAdd" && res?.payload?.statusCode === 201)
          form.resetFields();
        buttonType === "SaveAndAdd" && setSaveAndAddLoader(false);
        if (buttonType === "save" && res?.payload?.statusCode === 201) {
          closeDrawerByAnimation();
        }
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles["side-drawer-body"]}>
      <Form
        form={form}
        name="basic"
        initialValues={editSelectedRule}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        labelAlign="left"
        onValuesChange={() => form.validateFields()} // Validate fields dynamically on change
      >
        <div className={styles["side-drawer-form__inputs"]}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="effectiveDate"
                label={
                  <span>
                    Effective Date
                    <span style={{ color: "red", marginLeft: 4 }}>*</span>
                  </span>
                }
                className="custom-single-input required-icon-right"
                rules={[{ required: true, message: "Please enter a date" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="MM-DD-YYYY"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="countryIsoCode"
                label={
                  <span>
                    Country ISO Code
                    {form.getFieldValue("countryIsoCode") ||
                    form.getFieldValue("product") ||
                    form.getFieldValue("productCategory") ||
                    form.getFieldValue("customer") ? (
                      <span style={{ color: "red", marginLeft: 4 }}>*</span>
                    ) : null}
                  </span>
                }
                className="custom-single-input required-icon-right"
                rules={[
                  {
                    validator: (_, value, callback) => {
                      const { product, productCategory, customer } =
                        form.getFieldsValue();
                      if (value || product || productCategory || customer) {
                        callback();
                      } else {
                        callback(
                          "Please enter at least one of the required fields"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Country ISO Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="productCategory"
                label={
                  <span>
                    Product Category
                    {form.getFieldValue("countryIsoCode") ||
                    form.getFieldValue("product") ||
                    form.getFieldValue("productCategory") ||
                    form.getFieldValue("customer") ? (
                      <span style={{ color: "red", marginLeft: 4 }}>*</span>
                    ) : null}
                  </span>
                }
                className="custom-single-input required-icon-right"
                rules={[
                  {
                    validator: (_, value, callback) => {
                      const { countryIsoCode, product, customer } =
                        form.getFieldsValue();
                      if (value || countryIsoCode || product || customer) {
                        callback();
                      } else {
                        callback(
                          "Please enter at least one of the required fields"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Product Category" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="product"
                label={
                  <span>
                    Product
                    {form.getFieldValue("countryIsoCode") ||
                    form.getFieldValue("product") ||
                    form.getFieldValue("productCategory") ||
                    form.getFieldValue("customer") ? (
                      <span style={{ color: "red", marginLeft: 4 }}>*</span>
                    ) : null}
                  </span>
                }
                className="custom-single-input required-icon-right"
                rules={[
                  {
                    validator: (_, value, callback) => {
                      const { countryIsoCode, productCategory, customer } =
                        form.getFieldsValue();
                      if (
                        value ||
                        countryIsoCode ||
                        productCategory ||
                        customer
                      ) {
                        callback();
                      } else {
                        callback(
                          "Please enter at least one of the required fields"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Product" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="customer"
                label={
                  <span>
                    Customer
                    {form.getFieldValue("countryIsoCode") ||
                    form.getFieldValue("product") ||
                    form.getFieldValue("productCategory") ||
                    form.getFieldValue("customer") ? (
                      <span style={{ color: "red", marginLeft: 4 }}>*</span>
                    ) : null}
                  </span>
                }
                className="custom-single-input required-icon-right"
                rules={[
                  {
                    validator: (_, value, callback) => {
                      const { countryIsoCode, productCategory, product } =
                        form.getFieldsValue();
                      if (
                        value ||
                        countryIsoCode ||
                        productCategory ||
                        product
                      ) {
                        callback();
                      } else {
                        callback(
                          "Please enter at least one of the required fields"
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Customer" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="warrantyMonth"
                validateTrigger={["onBlur"]}
                label={
                  <span>
                    Warranty (months)
                    <span style={{ color: "red", marginLeft: 4 }}>*</span>
                  </span>
                }
                className="custom-single-input required-icon-right"
                rules={[
                  {
                    required: true,
                    message: "Please enter warranty period in months",
                  },
                ]}
              >
                <InputNumber
                  className="custom-single-input-number"
                  placeholder="Warranty (months)"
                  min={1}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name="comments"
                className={styles["side-drawer-form__single-input"]}
                label="Comments"
              >
                <Input.TextArea placeholder="Comments" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className={styles["side-drawer-form__buttons"]}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className={`${styles["side-drawer-form__save-and-add"]} ${
                isLoading && !saveAndAddLoader && "pointer-event-none"
              } `}
            >
              {isLoading && !saveAndAddLoader ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                  height={40}
                />
              ) : (
                "Save"
              )}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              size="large"
              className={styles["side-drawer-form__cancel"]}
              onClick={() => {
                closeDrawerByAnimation();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddRoleBody;
