import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { getApi, putApi } from "redux/apis";
import { toastText } from "utils/utils";
import styles from "./index.module.scss";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";

interface InventoryModalProps {
  open: boolean;
  onCancel: () => void;
  inventoryData: any;
  fetchInventory: () => void;
  isAdmin: boolean;
}

const InventoryEditModal = ({
  open,
  onCancel,
  inventoryData,
  fetchInventory,
  isAdmin,
}: InventoryModalProps) => {
  const [form] = Form.useForm();
  const [serviceCenters, setServiceCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [arrivalDate, setArrivalDate] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        const response = await getApi("/serviceCenter/shipment-dropdown");
        if (response.data.success) {
          setServiceCenters(
            response.data.data.map((center: any) => ({
              value: center.id,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch service centers:", error);
      }
    };

    fetchServiceCenters();
  }, []);

  useEffect(() => {
    if (inventoryData) {
      const initialArrivalDate = inventoryData.arrivalDate
        ? dayjs(inventoryData.arrivalDate)
        : null;

      setArrivalDate(initialArrivalDate);

      form.setFieldsValue({
        ...inventoryData,
        arrivalDate: initialArrivalDate,
        deliveryDate: inventoryData.deliveryDate
          ? dayjs(inventoryData.deliveryDate)
          : null,
        // Set default status based on arrival date
        status: initialArrivalDate ? inventoryData.status : "IN_TRANSIT",
      });
    }
  }, [inventoryData, form]);

  const handleArrivalDateChange = (date: dayjs.Dayjs | null) => {
    setArrivalDate(date);

    // Automatically set status when arrival date changes
    if (date) {
      form.setFieldsValue({ status: "RECEIVED" });
    } else {
      form.setFieldsValue({ status: "IN_TRANSIT" });
    }
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const formattedValues = {
        ...values,
        arrivalDate: values.arrivalDate?.format("YYYY-MM-DD"),
        deliveryDate: values.deliveryDate?.format("YYYY-MM-DD"),
        inventoryId: inventoryData.id,
      };

      await putApi("/inventory/update", formattedValues);

      toastText("Inventory updated successfully", "success");
      fetchInventory();
      onCancel();
    } catch (error: any) {
      toastText(
        error.response?.data?.message || "Failed to update inventory",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={800}
      closeIcon={null}
    >
      <div style={{ padding: "10px" }}>
        <Title level={4}>Edit Inventory</Title>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Service Center
              </label>
              <Form.Item name="serviceCenter">
                <Select
                  options={serviceCenters}
                  size="large"
                  placeholder="Select Service Center"
                  disabled
                  className={styles["inventory-modal__form--field-input"]}
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                3nStar Sales Order
              </label>
              <Form.Item name="salesOrderNumber">
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Delivery Date
              </label>
              <Form.Item name="deliveryDate">
                <DatePicker
                  size="large"
                  format="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Item#
              </label>
              <Form.Item name="itemNumber">
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Arrival Date
              </label>
              <Form.Item name="arrivalDate">
                <DatePicker
                  size="large"
                  format="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  onChange={handleArrivalDateChange}
                  disabled={!!inventoryData?.arrivalDate}
                  disabledDate={(currentDate) => {
                    return currentDate && currentDate.isAfter(dayjs(), "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Status
              </label>
              <Form.Item name="status">
                {/* <Select options={statusOptions} size="large" /> */}
                <Select
                  options={[
                    {
                      value: "IN_TRANSIT",
                      label: "In Transit",
                      disabled: !!arrivalDate,
                    },
                    {
                      value: "RECEIVED",
                      label: "Received",
                      disabled: !arrivalDate,
                    },
                    ...(isAdmin
                      ? [
                          {
                            value: "DAMAGED",
                            label: "Damaged",
                            disabled: !arrivalDate,
                          },
                          {
                            value: "LOST",
                            label: "Lost",
                            disabled: !arrivalDate,
                          },
                        ]
                      : []),
                  ]}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Item Description
              </label>
              <Form.Item name="itemDescription">
                <Input.TextArea size="large" rows={4} disabled />
              </Form.Item>
            </Col>
            <Col span={12} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Comments
              </label>
              <Form.Item name="comments">
                <Input.TextArea size="large" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="save-button"
              loading={loading}
            >
              Update
            </Button>
            <Button
              size="large"
              htmlType="button"
              style={{
                marginLeft: "10px",
              }}
              onClick={onCancel}
              className="cancel-button"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default InventoryEditModal;
