/* eslint-disable react-hooks/rules-of-hooks */
import { Space, Switch, Table } from "antd";
import { FC } from "react";
import {
  DeleteActionSvg,
  EditActionSvg,
  SortSvgBottom,
  SortSvgTop,
} from "utils/svgs";
import SearchAndFilter from "../SearchAndFilter/index";
import "./index.scss";
import { DynamicTableProps } from "./types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { editUserAction, getUsersAction } from "redux/action/userAction";
import { pageSizeOptionsPaging } from "utils/constant";

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const DynamicTable: FC<DynamicTableProps> = (props) => {
  // Inits
  const { Column } = Table;
  const {
    userDataSource,
    performSearchHandler,
    performFilterHandler,
    searchValue,
    filterValue,
    showModal,
    openDrawerHandler,
    setDrawerInfoHandler,
    setEditSelectedUser,
    performSortHandler,
    totalRecords,
    currentPage,
    PageSize,
    modifyPageSize,
    paginationChangeHandler,
    sortDirection,
    loggedInRoleName,
    profileData,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  // Edit user data handler
  const editDataHandler = (userObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("Edit User");
    setEditSelectedUser(userObject);
  };

  // Delete user data handler
  const deleteDataHandler = (userObject: any) => {
    setEditSelectedUser(userObject);
  };

  // Handle user status change
  const statusHandler = async (value: any, data: any) => {
    const finalData: any = {
      roleId: data?.roleId,
      userId: data?.userId,
      status: value,
      isChangeStatus: value,
    };

    try {
      await dispatch(editUserAction(finalData));

      const query: any = {
        page: currentPage,
        limit: PageSize,
        search: searchValue,
        filter: filterValue === "active",
        sort: "firstName",
        type: sortDirection === "ascend" ? "asc" : "desc",
      };

      if (filterValue === "all") {
        delete query?.filter;
      }

      await dispatch(getUsersAction(query));
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  // For handle the table change click
  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, pageInfo.current);
  };

  // JSX
  return (
    <div className={"dynamic-table"}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        performFilterHandler={performFilterHandler}
        filterValue={filterValue}
        PageSize={PageSize}
      />
      <Table
        dataSource={userDataSource}
        scroll={{ y: "calc(100vh - 450px)", x: "63vh" }}
        pagination={{
          total: totalRecords,
          current: currentPage,
          onChange: paginationChangeHandler,
          pageSize: PageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
          onShowSizeChange: modifyPageSize,
        }}
        className="table-global"
        onChange={tableChangeHandler}
      >
        <Column
          title="User Name"
          dataIndex="name"
          key="name"
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          className="bg-white"
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Email Address"
          dataIndex="email"
          key="email"
          className="bg-white"
        />
        <Column
          title="Phone Number"
          dataIndex="phone"
          key="phone"
          className="bg-white"
        />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          className="bg-white"
          width={"15%"}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"10%"}
          render={(value: any, data: any) => {
            const isLoggedInUser = data.email === profileData?.email; // Compare with logged-in user's email
            return (
              <Switch
                key={Math.random()}
                defaultChecked={value}
                disabled={data?.isCompanyAdmin || isLoggedInUser} // Disable if the user is a Company Admin or logged-in user
                onChange={(e) => statusHandler(e, data)}
              ></Switch>
            );
          }}
          // render={(value: any, data: any) => {
          //   return (
          //     <Switch
          //       key={Math.random()}
          //       defaultChecked={value}
          //       disabled={data?.isCompanyAdmin}
          //       onChange={(e) => statusHandler(e, data)}
          //     ></Switch>
          //   );
          // }}
        />
        {loggedInRoleName !== "Service Center Employee" && (
          <Column
            title="Action"
            dataIndex="action"
            key="action"
            className="bg-white"
            width={"15%"}
            render={(values: any, data: any) => {
              return (
                <Space size={20}>
                  {!(data.isCompanyAdmin || data.isAdmin) ? (
                    <>
                      <div
                        className="cursor-pointer flex align-center justify-center"
                        onClick={() => editDataHandler(data)}
                        style={{ marginRight: "2rem" }}
                      >
                        <EditActionSvg />
                      </div>
                      {/* {loggedInRoleName !== "Employee" && ( */}
                      <div
                        style={{
                          marginRight: "2rem",
                          cursor:
                            loggedInRoleName === "Employee"
                              ? "not-allowed"
                              : "pointer",
                          opacity: loggedInRoleName === "Employee" ? 0.5 : 1,
                        }}
                        className="cursor-pointer flex align-center justify-center"
                        onClick={() => {
                          if (loggedInRoleName !== "Employee") {
                            deleteDataHandler(data);
                            showModal();
                          }
                        }}
                        // onClick={() => {
                        //   deleteDataHandler(data);
                        //   showModal();
                        // }}
                      >
                        <DeleteActionSvg />
                      </div>
                      {/* )} */}
                    </>
                  ) : (
                    <>
                      <div
                        style={{ marginRight: "2rem" }}
                        className={"dynamic-table__no-action"}
                      >
                        <EditActionSvg />
                      </div>
                      <div
                        style={{ marginRight: "2rem" }}
                        className={"dynamic-table__no-action"}
                      >
                        <DeleteActionSvg />
                      </div>
                    </>
                  )}
                </Space>
              );
            }}
          />
        )}
      </Table>
    </div>
  );
};

export default DynamicTable;
