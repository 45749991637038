import React from "react";
import { Modal } from "antd";
import ShippingModal from "../ShippingModal";

const EditRecordModal = (props: any) => {
  const { isModalOpen, handleCancel, selectedPackageId } = props;
  return (
    <Modal
      title="Edit Records"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      className="full-screen-modal"
      style={{
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        maxWidth: "100vw",
        maxHeight: "100vh",
      }}
      destroyOnClose={true}
      onClose={handleCancel}
    >
      <ShippingModal
        packageId={selectedPackageId}
        isOpen={isModalOpen}
        handleShippingCancel={handleCancel}
      />
    </Modal>
  );
};

export default EditRecordModal;
