import {
  Loader,
  SideDrawerWrapper,
  TableActionHeader,
} from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { ruleColumns } from "constants/Data";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteWarrantyRulesActionTable,
  getWarrantyRulesActionTable,
} from "redux/action/warrantyRulesActions";
import { AppDispatch } from "redux/store";
import { AddSvg } from "utils/svgs";
import DynamicTable from "./Table";
import styles from "./index.module.scss";
import { userCheck } from "redux/slice/userCheckSlice";
import SearchAndFilter from "pages/Home/SearchAndFilter";
import ExtendWarrantyModal from "pages/Sales/ExtendWarrantyModal";
import AddRuleBody from "./AddRuleBody";
import { pageSizeOptionsPaging } from "utils/constant";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// Creating the list of role table
const RuleTable = (props: any) => {
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [drawerInfo, setDrawerInfo] = useState({
    drawerTitle: "Add Rule",
  });
  const { modifyPageSize } = props;
  interface Rule {
    id: any;
    effectiveDate: any;
    productCategory: any;
    countryIsoCode: any;
    customer: any;
    product: any;
    warrantyMonth: any;
    comments: any;
  }

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (roleName === "Employee") {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    // Redirect if Service Center Employee or Employee tries to access
    if (roleName === "Service Center Employee") {
      navigate("/");
    }
  }, [roleName, navigate]);

  const [filteredData, setFilterData] = useState<Rule[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSelectedRule, setEditSelectedRule] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [importRulesLoader, setImportRulesLoader] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchFields, setSearchFields] = useState<string[]>([
    "customer",
    "productCategory",
  ]);
  const dispatch = useDispatch<AppDispatch>();

  const { fistTimeFetchLoading } = useSelector(
    (state: any) => state?.warrantyRulesTable
  );
  const roleFetchLoading = useSelector(
    (state: any) => state?.warrantyRulesTable?.isLoading
  );

  const rulesData = useSelector(
    (state: any) => state?.warrantyRulesTable?.data
  );

  const { count } = useSelector((state: any) => state?.warrantyRulesTable);

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Formatting roleData and setting it in state
  useEffect(() => {
    console.log("currentPage", currentPage);
    // if (!rulesData) {
    dispatch(
      getWarrantyRulesActionTable({
        page: currentPage, // Page number
        limit: pageSize, // Page size or number of results per page
        searchParams: { fields: searchFields, value: searchValue }, // Search params
      })
    ).then((res: any) => {
      if (res?.payload?.error?.status === 401) {
        const response = {
          isLoggedIn: true,
          isErrorMessage: res?.payload?.error?.message,
        };
        dispatch(userCheck(response));
      }
    });

    // }
    if (rulesData && Array.isArray(rulesData)) {
      const formattedRules = rulesData?.map((rule: any) => ({
        id: rule.id, // Keeps the ID
        effectiveDate: dayjs(rule.effectiveDate).format("MM/DD/YYYY"), // Maps effectiveDate
        productCategory: rule.productCategory, // Maps productCategory
        countryIsoCode: rule.countryIsoCode, // Maps countryIsoCode
        customer: rule.customer, // Maps customer
        product: rule.product, // Maps product
        warrantyMonth: rule.warrantyMonth, // Maps warrantyMonth
        comments: rule.comments, // Maps comments
      }));

      setFilterData(formattedRules);
    }
  }, [dispatch, pageSize, currentPage, searchValue]);

  // Update itemDataSource whenever ItemDataState changes
  useEffect(() => {
    if (rulesData && Array.isArray(rulesData)) {
      const formattedRules = rulesData?.map((rule: any) => ({
        id: rule.id, // Keeps the ID
        effectiveDate: dayjs(rule.effectiveDate), // Maps effectiveDate
        productCategory: rule.productCategory, // Maps productCategory
        countryIsoCode: rule.countryIsoCode, // Maps countryIsoCode
        customer: rule.customer, // Maps customer
        product: rule.product, // Maps product
        warrantyMonth: rule.warrantyMonth, // Maps warrantyMonth
        comments: rule.comments, // Maps comments
      }));
      console.log("formatted Rules after Edit", formattedRules);
      setFilterData(formattedRules);
    }
  }, [rulesData]);

  // Remove from drawer handler
  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
  };
  // For open the sideDrawer with animation
  const openDrawerHandler = () => {
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
  };

  // For perform the close animation
  const closeDrawerByAnimation = () => {
    setDrawerAnimation(false);
    setEditSelectedRule(undefined);
  };

  //   For open the model
  const showModal = () => {
    setIsModalOpen(true);
  };

  // For change the data and title between components
  const setDrawerInfoHandler = (drawerTitle: any) => {
    setDrawerInfo({ drawerTitle });
  };

  //   For conform operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const deleteHandler = () => {
    if (editSelectedRule) {
      dispatch(
        deleteWarrantyRulesActionTable({ ruleId: editSelectedRule?.id })
      ).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        }

        // Calculate total pages after deletion
        const totalPages = Math.ceil((count - 1) / pageSize);

        // If current page is greater than total pages after deletion
        // or if we just deleted the last item on current page
        if (
          currentPage > totalPages ||
          (rulesData?.length === 1 && currentPage > 1)
        ) {
          setCurrentPage(currentPage - 1);
        } else {
          // Refresh current page
          dispatch(
            getWarrantyRulesActionTable({
              page: currentPage,
              limit: pageSize,
              searchParams: { fields: searchFields, value: searchValue },
            })
          );
        }

        setEditSelectedRule(undefined);
        setIsModalOpen(false);
      });
    }
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    console.log("value", value);

    setSearchValue(value);
    console.log("value", searchValue);

    setCurrentPage(1);
  };

  const resetButtonHandler = () => {
    setSearchValue("");
    setSearchFields(["customer", "productCategory"]);
    setCurrentPage(1);
  };

  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };
  const searchFieldsSelectHandler = (value: string[]) => {
    setSearchFields(value);
    setCurrentPage(1);
  };

  const options: { label: string; value: string }[] = ruleColumns
    .filter((column) => !["Action", "Date"].includes(column.title))
    .map((column: any) => ({
      label: column.title,
      value: column.dataIndex,
    }));

  return (
    <>
      <div className={styles["role-table"]}>
        {!fistTimeFetchLoading ? (
          <>
            <TableActionHeader title={"Warranty Rules"}>
              <div className={styles["role-table__action"]}>
                {localStorage.getItem("companyId") !== "undefined" && (
                  <button
                    className={`btn-blue ${styles["role-table__action--button"]}`}
                    onClick={() => {
                      openDrawerHandler();
                      setDrawerInfoHandler("Add Rule");
                    }}
                  >
                    <AddSvg />
                    <p>Add Rules</p>
                  </button>
                )}
              </div>
            </TableActionHeader>

            <SearchAndFilter
              performSearchHandler={performSearchHandler}
              searchValue={searchValue}
              PageSize={pageSize}
              searchFieldsSelectHandler={searchFieldsSelectHandler}
              resetButtonHandler={resetButtonHandler}
              searchFields={searchFields}
              handleExtendWarranty={setIsModalVisible}
              menuName="Warranty-Rules"
              options={options}
            />

            <div>
              <DynamicTable
                rulesDataSource={filteredData}
                ruleColumns={ruleColumns}
                showModal={showModal}
                openDrawerHandler={openDrawerHandler}
                setDrawerInfoHandler={setDrawerInfoHandler}
                setEditSelectedRule={setEditSelectedRule}
                isLoading={roleFetchLoading || importRulesLoader}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: count,
                  onChange: paginationChangeHandler,
                  pageSizeOptions: pageSizeOptionsPaging,
                  showSizeChanger: true,
                  onShowSizeChange: modifyPageSize,
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
      />

      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          <AddRuleBody
            closeDrawerByAnimation={closeDrawerByAnimation}
            editSelectedRule={editSelectedRule}
            setEditSelectedRule={setEditSelectedRule}
          />
        </SideDrawerWrapper>
      )}
      <ExtendWarrantyModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setIsLoading={setImportRulesLoader}
        ModalDetails={{ Name: "Import Rules" }}
        currentPage={currentPage}
        pageSize={pageSize}
        searchParams={{ fields: searchFields, value: searchValue }}
      />
    </>
  );
};

export default RuleTable;
