import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slice/loginSlice";
import profileSlice from "./slice/profileSlice";
import companySlice from "./slice/companySlice";
import ConnectionSlice from "./slice/connectionSlice";
import ActiveConnectionSlice from "./slice/activeConnectionslice";
import IntegrationSlice from "./slice/integrationSlice";
import userCheckSlice from "./slice/itemsSlice";
import QbdItemSlice from "./slice/itemsSlice";
import ProductWarrantyDetailsSlice from "./slice/productWarrantyDetailsSlice";
import QbdUserSlice from "./slice/qbdUserSlice";
import UserSlice from "./slice/userSlice";
import RoleSlice from "./slice/roleSlice";
import RoleTableSlice from "./slice/roleTableSlice";
import warrantyRulesTableSlice from "./slice/warrantyRulesTableSlice";
import defaultMonthSlice from "./slice/defaultMonthSlice";

const store = configureStore({
  reducer: {
    auth: loginSlice.reducer,
    userProfile: profileSlice.reducer,
    companies: companySlice.reducer,
    Connection: ConnectionSlice.reducer,
    ActiveServices: ActiveConnectionSlice.reducer,
    Integration: IntegrationSlice.reducer,
    userCheck: userCheckSlice.reducer,
    qbdItems: QbdItemSlice.reducer,
    ProductWarrantyDetails: ProductWarrantyDetailsSlice.reducer,
    qbdUser: QbdUserSlice.reducer,
    roleTable: RoleTableSlice.reducer,
    warrantyRulesTable: warrantyRulesTableSlice.reducer,
    defaultMonth: defaultMonthSlice.reducer,
    users: UserSlice.reducer,
    roles: RoleSlice.reducer,
  },
});

export default store;
export type AppDispatch = typeof store.dispatch;
