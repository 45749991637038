import { Button, Col, DatePicker, Form, Modal, Row, Select, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getApi, putApi } from "redux/apis";
import { toastText } from "utils/utils";
import { pageSizeOptionsPaging } from "utils/constant";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface Props {
  open: boolean;
  onCancel: () => void;
  fetchInventory: any;
}

const { Option } = Select;

export const InventoryModal = ({ open, onCancel, fetchInventory }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [bulkArrivalDate, setBulkArrivalDate] = useState<dayjs.Dayjs | null>(
    null
  );
  const [selectedServiceCenter, setSelectedServiceCenter] = useState("");
  const [dataSource, setDataSource] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryDataFromShipment, setInventoryDataFromShipment] =
    useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [PageSize, setPageSize] = useState(
    Number(localStorage.getItem("userTableSize")) || 10
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [serviceCenters, setServiceCenters] = useState<
    { value: string; label: string }[]
  >([]);

  const [localUpdates, setLocalUpdates] = useState<Record<number, any>>({});

  const fetchAllInventoryFromShipment = async () => {
    try {
      const query: any = {
        page: currentPage,
        searchValue: searchValue,
        pageSize: PageSize,
      };

      if (searchValue === "All") {
        delete query.searchValue;
      }
      const response = await getApi("/inventory?excludeShipped=false", query);
      const inventoryDetails = response?.data?.data;
      setTotalRecords(response.data?.data.total);

      setInventoryDataFromShipment(inventoryDetails);
    } catch (error: any) {
      console.log("error: ", error);
    }
  };
  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        const response = await getApi("/serviceCenter/shipment-dropdown");
        if (response.data.success) {
          setServiceCenters([
            { value: "All", label: "All" },
            ...response.data.data.map((center: any) => ({
              value: center.name,
              label: center.name,
            })),
          ]);
          setSelectedServiceCenter("All");
        }
      } catch (error) {
        console.error("Failed to fetch service centers:", error);
      }
    };

    fetchServiceCenters();
  }, []);

  useEffect(() => {
    fetchAllInventoryFromShipment();
  }, [PageSize, searchValue, currentPage]);

  useEffect(() => {
    if (inventoryDataFromShipment) {
      const formattedData = inventoryDataFromShipment?.data?.map(
        (item: any, index: number) => {
          const updatedData = localUpdates[item.id] || {};
          return {
            key: item.id,
            id: item.id,
            serialNumber: (currentPage - 1) * PageSize + index + 1,
            serviceCenter: item.serviceCenter,
            salesOrder: item.salesOrderNumber,
            deliveryDate: item.deliveryDate ? dayjs(item.deliveryDate) : null,
            itemNumber: item.itemNumber,
            arrivalDate: updatedData.arrivalDate
              ? updatedData.arrivalDate
              : item.arrivalDate
              ? dayjs(item.arrivalDate)
              : null,
            status:
              updatedData.status ||
              (item.status === "SHIPPED" ? "IN_TRANSIT" : item.status),
          };
        }
      );
      setDataSource(formattedData);
    }
  }, [inventoryDataFromShipment, currentPage, PageSize, localUpdates]);
  const handleBulkDateChange = (date: dayjs.Dayjs | null) => {
    setBulkArrivalDate(date);
  };

  const handleBatchAction = () => {
    if (!bulkArrivalDate || selectedRowKeys.length === 0) return;

    Modal.confirm({
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ExclamationCircleOutlined
            style={{ fontSize: "24px", color: "#faad14", marginRight: "8px" }}
          />
          <span>Confirmation Required</span>
        </div>
      ),
      content: (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          You are about to mark <strong>{selectedRowKeys.length}</strong> items
          as <strong>Received</strong> with the arrival date{" "}
          <strong>{bulkArrivalDate.format("MM/DD/YYYY")}</strong>.
        </div>
      ),
      icon: null,
      footer: (
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              padding: "6px 16px",
              backgroundColor: "#1890ff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginRight: "8px",
            }}
            onClick={() => {
              // Call the onOk logic manually
              const updates = selectedRowKeys.reduce((acc, id) => {
                acc[id] = { arrivalDate: bulkArrivalDate, status: "RECEIVED" };
                return acc;
              }, {} as Record<number, any>);

              setLocalUpdates((prev) => ({ ...prev, ...updates }));
              setBulkArrivalDate(null);
              setSelectedRowKeys([]);
              Modal.destroyAll(); // Close the modal
            }}
          >
            Yes, Proceed
          </button>
          <button
            style={{
              padding: "6px 16px",
              backgroundColor: "#f0f0f0",
              color: "#000",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              Modal.destroyAll(); // Close the modal on Cancel
            }}
          >
            Cancel
          </button>
        </div>
      ),
      style: {
        top: "50%",
        transform: "translateY(-50%)",
      },
    });
  };

  const handleDateChange = (date: dayjs.Dayjs | null, recordKey: number) => {
    setLocalUpdates((prev) => ({
      ...prev,
      [recordKey]: {
        ...prev[recordKey],
        arrivalDate: date,
        status: date ? "RECEIVED" : "IN_TRANSIT",
      },
    }));
  };

  const handleStatusChange = (value: string, recordKey: number) => {
    const updatedData = dataSource.map((row: any) =>
      row.id === recordKey ? { ...row, status: value } : row
    );
    setDataSource(updatedData);
  };

  const handleServiceCenterChange = (value: any) => {
    setSelectedServiceCenter(value);
    setSearchValue(value);
    setCurrentPage(1);
  };

  const onFinish = async () => {
    // Only get data for rows that have been modified
    const modifiedRows = dataSource
      .filter((row: any) => localUpdates[row.id])
      .map((row: any) => {
        const updatedRow = localUpdates[row.id];
        return {
          id: row.id,
          arrivalDate: updatedRow.arrivalDate
            ? updatedRow.arrivalDate.format("YYYY-MM-DD")
            : null,
          status: updatedRow.arrivalDate ? "RECEIVED" : "IN_TRANSIT",
        };
      });

    // For unmodified rows that are selected, add them with IN_TRANSIT status
    const unmodifiedSelectedRows = dataSource
      .filter(
        (row: any) => selectedRowKeys.includes(row.id) && !localUpdates[row.id]
      )
      .map((row: any) => ({
        id: row.id,
        arrivalDate: null,
        status: "IN_TRANSIT",
      }));

    const allRows = [...modifiedRows, ...unmodifiedSelectedRows];

    // Don't proceed if no rows to process
    if (allRows.length === 0) {
      toastText("No changes to save", "info");
      return;
    }

    try {
      setIsLoading(true);
      const response = await putApi("/inventory/create", allRows);
      const message =
        response?.data?.message || "Inventory status updated successfully.";
      toastText(message, "success");
      onCancel();
      fetchInventory();
    } catch (error: any) {
      const message =
        error?.response?.data?.error ||
        "Something went wrong while updating inventory status.";
      toastText(message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle pagination for the table
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  // Modify the page size
  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);
      localStorage.setItem("userTableSize", size.toString());
    }
    setCurrentPage(1);
  };

  // Table columns
  const columns = [
    {
      title: "#",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: "80px",
    },
    {
      title: "Service Center",
      dataIndex: "serviceCenter",
      key: "serviceCenter",
    },
    {
      title: "3nStar Sales Order",
      dataIndex: "salesOrder",
      key: "salesOrder",
    },
    {
      title: "Item#",
      dataIndex: "itemNumber",
      key: "itemNumber",
    },
    {
      title: "Arrival Date",
      dataIndex: "arrivalDate",
      key: "arrivalDate",
      render: (value: dayjs.Dayjs, record: any) => {
        return (
          <DatePicker
            value={value}
            format="MM/DD/YYYY"
            onChange={(date) => {
              handleDateChange(date, record.id);
            }}
            disabledDate={(current) => {
              return current && current.isAfter(dayjs(), "day");
            }}
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) => (
        <Select
          value={record.status}
          onChange={(value) => handleStatusChange(value, record.id)}
          style={{ width: 150 }}
        >
          <Option
            value="IN_TRANSIT"
            key="IN_TRANSIT"
            disabled={record.arrivalDate !== null}
          >
            In Transit
          </Option>
          <Option
            value="RECEIVED"
            key="RECEIVED"
            disabled={record.arrivalDate === null}
          >
            Received
          </Option>
        </Select>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      footer={null}
      width={1500}
      title="Add Inventory"
      onCancel={onCancel}
    >
      <div>
        <Form onFinish={onFinish}>
          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col span={8}>
              <div className={styles["modal-header"]}>
                <p>Service Center Name: </p>
                <Select
                  placeholder="Select Service Center"
                  onChange={handleServiceCenterChange}
                  style={{ width: "55%" }}
                  value={selectedServiceCenter}
                  options={serviceCenters}
                ></Select>
              </div>
            </Col>
            {selectedRowKeys.length > 0 && (
              <>
                <Col span={8}>
                  <div className={styles["modal-header"]}>
                    <p>Bulk Update Arrival Date: </p>
                    <DatePicker
                      placeholder="Bulk Update Arrival Date"
                      value={bulkArrivalDate}
                      onChange={handleBulkDateChange}
                      style={{ width: "80%" }}
                      format="MM/DD/YYYY"
                      disabledDate={(currentDate) => {
                        return (
                          currentDate && currentDate.isAfter(dayjs(), "day")
                        );
                      }}
                    />
                  </div>
                </Col>

                <Col span={8}>
                  <div className={styles["modal-header"]}>
                    <p>Batch Action: </p>
                    <Button
                      type="primary"
                      className={styles["search-filter__markReceived"]}
                      onClick={handleBatchAction}
                      style={{ width: "35%" }}
                      disabled={
                        !bulkArrivalDate || selectedRowKeys.length === 0
                      }
                    >
                      Mark as Received
                    </Button>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Table
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys,
                  onChange: (keys: any) => setSelectedRowKeys(keys),
                }}
                columns={columns}
                dataSource={dataSource}
                scroll={{ y: "calc(100vh - 480px)", x: "60vh" }}
                loading={isLoading}
                pagination={{
                  total: totalRecords,
                  current: currentPage,
                  onChange: paginationChangeHandler,
                  pageSize: PageSize,
                  pageSizeOptions: pageSizeOptionsPaging,
                  showSizeChanger: true,
                  onShowSizeChange: modifyPageSize,
                }}
              />
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="save-button"
            >
              {"Save"}
            </Button>
            <Button
              size="large"
              htmlType="button"
              style={{
                marginLeft: "10px",
              }}
              onClick={() => {
                onCancel();
              }}
              className="cancel-button"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
