import { Input, Space, Table, Tooltip } from "antd";
import { ChangeEvent, FC, useEffect, useState } from "react";

import {
  DeleteActionSvg,
  EditActionSvg,
  SortSvgBottom,
  SortSvgTop,
} from "utils/svgs";
import styles from "./index.module.scss";
import "./index.scss";
import { DynamicTableProps } from "./types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { editDefaultWarrantyMonthRuleActionTable } from "redux/action/warrantyRulesActions";
import { getDefaultMonthActionTable } from "redux/action/defaultMonthActions";
import dayjs from "dayjs";

const DynamicTable: FC<DynamicTableProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const profileData = useSelector((state: any) => state?.userProfile?.data);

  const { Column } = Table;
  const {
    rulesDataSource,
    showModal,
    openDrawerHandler,
    setDrawerInfoHandler,
    setEditSelectedRule,
    pagination,
    isLoading,
  } = props;

  const defaultMonth = useSelector((state: any) => state?.defaultMonth?.data);

  const [defaultWarrantyMonths, setDefaultWarrantyMonths] =
    useState<number>(12);

  useEffect(() => {
    dispatch(getDefaultMonthActionTable());
    if (defaultMonth) setDefaultWarrantyMonths(defaultMonth.warrantyMonth);
  }, [dispatch]);

  useEffect(() => {
    if (defaultMonth) {
      if (defaultMonth) setDefaultWarrantyMonths(defaultMonth.warrantyMonth);
    }
  }, [defaultMonth]);

  // Handle input change
  const handleDefaultWarrantySave = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setDefaultWarrantyMonths(value);
    dispatch(
      editDefaultWarrantyMonthRuleActionTable({
        id: defaultMonth?.id || null,
        warrantyMonth: value,
        ruleName: defaultMonth?.ruleName || null,
        userId: profileData?.userId || null,
      })
    );
  };

  const handleDefaultWarrantyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setDefaultWarrantyMonths(value);
  };

  // Handle edit data action
  const editDataHandler = (ruleObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("Edit Rule");
    setEditSelectedRule({
      ...ruleObject,
      warrantyMonth: ruleObject?.warrantyMonth,
      comments: ruleObject?.comments,
    });
  };

  // Handle delete data action
  const deleteDataHandler = (roleObject: any) => {
    setEditSelectedRule({
      ...roleObject,
      warrantyMonth: roleObject?.warrantyMonth,
      comments: roleObject?.comments,
    });
  };

  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);

  const handleSortChange = (pagination: any, filters: any, sorter: any) => {
    // If no sort order is applied, set it to ascend
    if (!sorter.order) {
      setSortOrder(null);
    } else {
      // Toggle sort order between 'ascend' and 'descend'
      setSortOrder(sorter.order);
    }
  };

  // JSX
  return (
    <div className={styles["dynamic-table"]}>
      <Table
        dataSource={rulesDataSource}
        className="table-global"
        loading={isLoading}
        onChange={handleSortChange}
        pagination={pagination}
        scroll={{ y: "calc(100vh - 450px)", x: "63vh" }}
      >
        <Column
          sortOrder={sortOrder}
          showSorterTooltip={{ title: "" }}
          sorter={(a: any, b: any) =>
            new Date(a.effectiveDate).getTime() -
            new Date(b.effectiveDate).getTime()
          }
          title="Date"
          dataIndex="effectiveDate"
          key="effectiveDate"
          width={"15%"}
          className="bg-white"
          sortIcon={(data) => {
            return data.sortOrder === "ascend" ? (
              <SortSvgTop />
            ) : (
              <SortSvgBottom />
            );
          }}
          render={(text) => (text ? dayjs(text).format("MM-DD-YYYY") : "-")}
        />
        <Column
          title="Product Category"
          dataIndex="productCategory"
          key="productCategory"
          width={"20%"}
          className="bg-white"
          render={(text) => (text ? text : "-")}
        />
        <Column
          title="Country ISO Code"
          dataIndex="countryIsoCode"
          key="countryISOCode"
          width={"15%"}
          className="bg-white"
          render={(text) => (text ? text : "-")}
        />
        <Column
          title="Customer"
          dataIndex="customer"
          key="customer"
          width={"15%"}
          className="bg-white"
          render={(text) => (text ? text : "-")}
        />
        <Column
          title="Product"
          dataIndex="product"
          key="product"
          width={"15%"}
          className="bg-white"
          render={(text) => (text ? text : "-")}
        />
        <Column
          title={
            <div className={styles["warranty-header"]}>
              <span>Warranty (months)</span>
              <div>
                {" "}
                <Input
                  type="number"
                  value={defaultWarrantyMonths}
                  onBlur={handleDefaultWarrantySave}
                  onChange={handleDefaultWarrantyChange}
                  style={{ width: "50px", marginLeft: "2px" }}
                />
                <Tooltip title="Default warranty months">
                  <InfoCircleOutlined
                    style={{
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#1890ff",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          }
          dataIndex="warrantyMonth"
          key="warranty"
          width={"15%"}
          className="bg-white"
          render={(text) => (text ? text : "-")}
        />
        <Column
          title="Comments"
          dataIndex="comments"
          key="comments"
          width={"25%"}
          className="bg-white"
          render={(text) => (text ? text : "-")}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"11%"}
          render={(value, data: any) => (
            <Space size={20}>
              <div
                className="cursor-pointer flex align-center justify-center"
                onClick={() => editDataHandler(data)}
                style={{ marginRight: "2rem" }}
              >
                <EditActionSvg />
              </div>
              <div
                className="cursor-pointer flex align-center justify-center"
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  deleteDataHandler(data);
                  showModal();
                }}
              >
                <DeleteActionSvg />
              </div>
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default DynamicTable;
