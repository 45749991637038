import { createSlice } from "@reduxjs/toolkit";
import {
  getWarrantyDetailsActionTable,
  paginateWarrantyDetailsTable,
  addWarrantyDetailActionTable,
  updateWarrantyExpiryDateActionTable,
  deleteWarrantyDetailActionTable,
} from "redux/action/productWarrantyDetailsAction";
import { toastText } from "utils/utils";

const initialState: any = {
  data: null,
  firstTimeFetchLoading: true,
  total: 0,
  isLoading: false,
  count: 0,
  error: null,
};

const ProductWarrantyDetailsSlice = createSlice({
  name: "ProductWarrantyDetails",
  initialState,
  reducers: {
    clearRedux: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    // Get All Product Warranty Details
    builder.addCase(getWarrantyDetailsActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      getWarrantyDetailsActionTable.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action?.payload;
        state.count = action?.payload?.count;
      }
    );
    builder.addCase(
      getWarrantyDetailsActionTable.rejected,
      (state, action: any) => {
        state.error = action.payload;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
        state.isLoading = false;
        state.firstTimeFetchLoading = false;
      }
    );

    // Paginate Product Warranty Details
    builder.addCase(paginateWarrantyDetailsTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
    });

    // Add New Product Warranty Detail
    builder.addCase(addWarrantyDetailActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(addWarrantyDetailActionTable.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = [...state.data, action.payload.data];
      state.count = state.count + 1;
      toastText(action?.payload?.message, "success");
    });
    builder.addCase(
      addWarrantyDetailActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );

    // Update Warranty Expiry Date
    builder.addCase(updateWarrantyExpiryDateActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      updateWarrantyExpiryDateActionTable.fulfilled,
      (state, action) => {
        state.isLoading = false;
        const updatedData = action.payload.updatedData;

        state.data = state.data.map((detail: any) => {
          if (detail?.id === updatedData.id) {
            return {
              ...detail,
              expiresDate: updatedData?.expiresDate || detail?.expiresDate,
            };
          }
          return detail;
        });

        toastText("Warranty expiry date updated successfully", "success");
      }
    );
    builder.addCase(
      updateWarrantyExpiryDateActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );

    // Delete Product Warranty Detail
    builder.addCase(deleteWarrantyDetailActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      deleteWarrantyDetailActionTable.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = state?.data?.filter(
          (item: any) => item?.id !== action?.payload?.id
        );
        state.count = state.count - 1;
        toastText(action?.payload?.response?.message, "success");
      }
    );
    builder.addCase(
      deleteWarrantyDetailActionTable.rejected,
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.response;
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
      }
    );
  },
});

export default ProductWarrantyDetailsSlice;
