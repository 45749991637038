import { BarsOutlined } from "@ant-design/icons";
import {
  EmailSvg,
  IntegrationsSvg,
  PasswordSvg,
  // RoleSvg,
  RulesIcon,
  // RoleSvg,
  // SubscriptionSvg,
  UserSvg,
  // UsersSvg,

  // ConfigurationsSvg
} from "utils/svgs";

const phoneNumberValidator = (_: any, value: any) => {
  // Modify this regex pattern to match the format of phone numbers you want to validate
  const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  if (!value || value.match(phoneRegex)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error("Please enter a valid phone number"));
};

export const userColumns = [
  {
    title: "Organization Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const FORMDATA = {
  loginFields: [
    {
      title: "Email address",
      id: "email",
      type: "text",
      name: "email",
      svg: <EmailSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  resetPassword: [
    {
      title: "New Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Confirm Password",
      id: "confirmPassword",
      type: "password",
      name: "confirmPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password again",
          validationTrigger: "onChange",
        },
        ({ getFieldValue }: any) => ({
          validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match"));
          },
          validateTrigger: "onChange",
        }),
      ],
    },
  ],
  addUserFields: [
    {
      title: "Full Name",
      id: "fullName",
      type: "text",
      name: "fullName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your full name",
          validateTrigger: "onChange",
        },
        {
          max: 30,
          message: "Full name length must be less than 30 characters",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Select Role",
      id: "roleName",
      type: "text",
      name: "roleName",
      defaultValue: "",
      placeholder: "Enter role name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please select role",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid email address",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Phone Number",
      id: "phone",
      type: "text",
      name: "phone",
      placeHolder: "",
      required: true,
      rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
    },
    {
      title: "Select Service Center",
      id: "serviceCenters",
      type: "text",
      name: "serviceCenters",
      defaultValue: "",
      placeholder: "Enter service center",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please select service center",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  forgotPassword: [
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  addRoleFields: [
    {
      title: "Role Name",
      id: "roleName",
      type: "text",
      name: "roleName",
      defaultValue: "",
      placeholder: "Enter role name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter role name",
          validateTrigger: "onChange",
        },
        {
          max: 50,
          message: "Role name length must be less than 50 characters",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Description",
      id: "roleDescription",
      type: "textArea",
      name: "roleDescription",
      defaultValue: "",
      placeholder: "Enter role description",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter role description",
          validateTrigger: "onSubmit",
        },
        {
          max: 200,
          message: "Role description length must be less than 200 characters",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  addRuleFields: [
    {
      title: "Date",
      id: "ruleDate",
      type: "date",
      name: "ruleDate",
      defaultValue: "",
      placeholder: "dd-mm-yyyy",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter a date",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Product Category",
      id: "productCategory",
      type: "text",
      name: "productCategory",
      defaultValue: "",
      placeholder: "Product Category",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter a product category",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Country ISO Code",
      id: "countryISOCode",
      type: "text",
      name: "countryISOCode",
      defaultValue: "",
      placeholder: "Country ISO Code",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter a country ISO code",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Customer",
      id: "customer",
      type: "text",
      name: "customer",
      defaultValue: "",
      placeholder: "Customer",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter a customer",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Product",
      id: "product",
      type: "text",
      name: "product",
      defaultValue: "",
      placeholder: "Product",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter a product",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Warranty (months)",
      id: "warranty",
      type: "number",
      name: "warranty",
      defaultValue: "",
      placeholder: "Warranty (months)",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter warranty period in months",
          validateTrigger: "onChange",
        },
        {
          type: "number",
          min: 0,
          message: "Warranty must be a positive number",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Comments",
      id: "comments",
      type: "textArea",
      name: "comments",
      defaultValue: "",
      placeholder: "Comments",
      required: false,
      rules: [],
    },
  ],
  settingsMenuItems: [
    {
      key: "integrations",
      icon: <IntegrationsSvg />,
      label: "Integrations",
    },
    {
      key: "users",
      icon: <UserSvg />,
      label: "Users",
    },
    {
      key: "warranty-rules",
      icon: <RulesIcon />,
      label: "Warranty Rules",
    },
    {
      key: "service-centers",
      icon: <UserSvg />,
      label: "Service Centers",
    },
    {
      key: "item",
      icon: <BarsOutlined style={{ color: "black", fontSize: "24px" }} />,
      label: "Items",
    },
  ],
  newIntegrationMenuItems: [
    {
      key: "connectedApps",
      label: "Connected Apps",
    },
    {
      key: "accounting",
      label: "Accounting",
    },
  ],
  pageMenuItems: [
    {
      key: "",
      label: "Item Inventory",
    },
    {
      key: "sales",
      label: "Sales",
    },
    {
      key: "inventory-control",
      label: "Spare Part Inventory",
    },

    {
      key: "sales-order",
      label: "Sales Order",
    },
    {
      key: "shipping",
      label: "Shipping",
    },
    {
      key: "packing-list",
      label: "Packing List",
    },
  ],
  qbdIntegrationFields: [
    {
      title: "User Name",
      id: "username",
      type: "qbd",
      name: "username",
      svg: <UserSvg />,
      placeHolder: "",
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
        {
          min: 6,
          message: "Password must be at least 6 characters long",
        },
      ],
    },
  ],
  addServiceCenterFields: [
    {
      title: "Name",
      id: "name",
      type: "text",
      name: "name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your name",
          validateTrigger: "onChange",
        },
        {
          max: 30,
          message:
            "The maximum length is 30 characters. Please shorten your name.",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Primary Contact Name",
      id: "primaryContact",
      type: "text",
      name: "primaryContact",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your primary contact name",
          validateTrigger: "onChange",
        },
        {
          max: 30,
          message:
            "The maximum length is 30 characters. Please shorten your primary contact name.",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Phone Number",
      id: "contactNumber",
      type: "text",
      name: "contactNumber",
      placeHolder: "",
      required: true,
      rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
    },
    {
      title: "Address",
      id: "address",
      type: "textArea",
      name: "address",
      defaultValue: "",
      placeholder: "Address",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your address",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  shipmentFields: [
    {
      title: "Service Center",
      id: "serviceCenterName",
      type: "text",
      name: "serviceCenterName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter service center name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Sales Order",
      id: "salesOrder",
      type: "text",
      name: "salesOrder",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter sales order",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Item Name",
      id: "itemNumber",
      type: "text",
      name: "itemNumber",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter item name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Quantity",
      id: "quantity",
      type: "text",
      name: "quantity",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter quantity",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Item Description",
      id: "itemDescription",
      type: "text",
      name: "itemDescription",
      placeHolder: "",
      required: false,
      rules: [],
    },
    {
      title: "Delivery Date",
      id: "deliveryDate",
      type: "date",
      name: "deliveryDate",
      defaultValue: "",
      placeholder: "dd-mm-yyyy",
      required: false,
      rules: [],
    },

    {
      title: "Select Status",
      id: "status",
      type: "text",
      name: "status",
      defaultValue: "",
      placeholder: "Enter Status",
      placeHolder: "",
      required: false,
      rules: [],
    },
  ],
  editInventoryFields: [
    {
      title: "Service Center",
      id: "serviceCenterName",
      type: "text",
      name: "serviceCenterName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter service center name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Sales Order",
      id: "salesOrder",
      type: "text",
      name: "salesOrder",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter sales order",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Item Name",
      id: "itemName",
      type: "text",
      name: "itemName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter item name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Arrival Date",
      id: "arrivalDate",
      type: "date",
      name: "arrivalDate",
      defaultValue: "",
      placeholder: "dd-mm-yyyy",
      required: false,
      rules: [],
    },
    {
      title: "Delivery Date",
      id: "deliveryDate",
      type: "date",
      name: "deliveryDate",
      defaultValue: "",
      placeholder: "dd-mm-yyyy",
      required: false,
      rules: [],
    },
    {
      title: "Select Status",
      id: "status",
      type: "text",
      name: "status",
      defaultValue: "",
      placeholder: "Enter Status",
      placeHolder: "",
      required: false,
      rules: [],
    },
    {
      title: "Item Description",
      id: "itemDescription",
      type: "text",
      name: "itemDescription",
      placeHolder: "",
      required: false,
      rules: [],
    },
    {
      title: "Comments",
      id: "comment",
      type: "text",
      name: "comment",
      placeHolder: "",
      required: false,
      rules: [],
    },
  ],
  registerSparePartsFields: [
    {
      title: "Service Center",
      id: "serviceCenterName",
      type: "text",
      name: "serviceCenterName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter service center name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Customer Name",
      id: "customerName",
      type: "text",
      name: "customerName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter customer name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Product Repaired",
      id: "quickbookProduct",
      type: "text",
      name: "quickbookProduct",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter quickbook Product Name",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Choose Spare Part",
      id: "sparePart",
      type: "text",
      name: "sparePart",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please select sparepart",
          validateTrigger: "onChange",
        },
        // {
        //   max: 30,
        //   message: "Full name length must be less than 30 characters",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Use Date",
      id: "useDate",
      type: "date",
      name: "useDate",
      defaultValue: "",
      placeholder: "dd-mm-yyyy",
      required: false,
      rules: [],
    },
    {
      title: "Select Status",
      id: "status",
      type: "text",
      name: "status",
      defaultValue: "",
      placeholder: "Enter Status",
      placeHolder: "",
      required: false,
      rules: [],
    },
    {
      title: "Service Center Ticket Number",
      id: "serviceCenterTicket",
      type: "text",
      name: "serviceCenterTicket",
      placeHolder: "",
      required: false,
      rules: [],
    },
    {
      title: "Product Description",
      id: "productDescription",
      type: "text",
      name: "productDescription",
      placeHolder: "",
      required: false,
      rules: [],
    },
  ],
};

export const integrationDataSource: any = [
  {
    key: "1",
    Accounting_Software: {
      connection: "QBO",
      company: "sandbox",
    },
    Ecommerce_Software: {
      connection: "Magento",
      company: "Magento 1",
    },
    Status: true,
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    Accounting_Software: {
      connection: "XERO",
      company: "sandbox",
    },
    Ecommerce_Software: {
      connection: "Shopify",
      company: "shopify 1",
    },
    Status: false,
    tags: ["nice", "developer"],
  },
  {
    key: "3",
    Accounting_Software: {
      connection: "ZOHO",
      company: "ZohoBooks 1",
    },
    Ecommerce_Software: {
      connection: "ShopWare",
      company: "ShopWare 1",
    },
    Status: true,
    tags: ["nice", "developer"],
  },
];

export const TechnicalSupportEmailForm = [
  {
    title: "Subject",
    id: "emailSubject",
    type: "text",
    name: "emailSubject",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter subject",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter an email subject",
        validateTrigger: "onChange",
      },
      {
        min: 3,
        message: "Email subject should be at least 3 characters long",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "How can we help you today?",
    id: "emailBody",
    type: "textarea",
    name: "emailBody",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please describe the issue",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter details about your issue",
        validateTrigger: "onChange",
      },
      {
        max: 500,
        message: "Content should not exceed 500 characters",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "Attachments",
    id: "emailAttachment",
    type: "fileUpload",
    name: "emailAttachment",
    defaultValue: "",
    disabled: false,
    errorMessage: "Add Attachment if any",
    required: false,
    rules: [],
  },
  {
    title: "Phone Number",
    id: "phone",
    type: "phone",
    name: "phone",
    placeHolder: "",
    required: true,
    rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
  },
  {
    title: "How critical is your request ?",
    id: "issueIntensity",
    type: "dropdown",
    name: "issueIntensity",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please select the inetnsity of your issue",
    required: true,
    rules: [
      {
        required: true,
        message: "Please select the intensity of your issue",
        validateTrigger: "onChange",
      },
    ],
  },
];
export const UserProfileData = [
  {
    title: "First name",
    id: "firstName",
    type: "text",
    name: "firstName",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your first name",
    required: true,
  },
  {
    title: "Last name",
    id: "lastName",
    type: "text",
    name: "lastName",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your last name",
    required: true,
  },
  {
    title: "Email address",
    id: "email",
    type: "text",
    name: "email",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your email",
    required: false,
    rules: [],
  },
  {
    title: "Phone Number",
    id: "phone",
    type: "number",
    name: "phone",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter your number",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter your number",
        validateTrigger: "onChange",
      },
      { validator: phoneNumberValidator, validateTrigger: "onChange" },
    ],
  },
];

export const roleColumns = [
  {
    title: "Role Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Permissions",
    dataIndex: "permissions",
    key: "permissions",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const ruleColumns = [
  {
    title: "Date",
    dataIndex: "ruleDate",
    key: "ruleDate",
    sorter: (a: any, b: any) => {
      return new Date(a.ruleDate).getTime() - new Date(b.ruleDate).getTime();
    },
    sortDirections: ["descend"],
  },
  {
    title: "Product Category",
    dataIndex: "productCategory",
    key: "productCategory",
    sorter: (a: any, b: any) => {
      return a.productCategory.length - b.productCategory.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Country ISO Code",
    dataIndex: "countryIsoCode",
    key: "countryISOCode",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
  },
  {
    title: "Warranty (months)",
    dataIndex: "warrantyMonth",
    key: "warranty",
    sorter: (a: any, b: any) => {
      return a.warranty - b.warranty;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];
