import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteApiWithData, getApi, postApi, putApi } from "redux/apis";

// Get All Product Warranty Details with Pagination & Filters
export const getWarrantyDetailsActionTable = createAsyncThunk(
  "warrantyDetailsTable/getWarrantyDetailsTable",
  async (
    { pageNumber, pageSize, searchParams }: any,
    { rejectWithValue }: any
  ) => {
    try {
      const response = await postApi(`/productWarrantyDetails?`, {
        pageNumber,
        pageSize,
        searchParams,
      });
      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Paginate Warranty Details
export const paginateWarrantyDetailsTable = createAsyncThunk(
  "warrantyDetailsTable/paginateTable",
  async (url: any, { rejectWithValue, getState }: any) => {
    const alreadyAvailableDetails = getState().warrantyDetails.data;
    try {
      const response = await getApi(
        `/product-warranty-details${url ? `?${url}` : ""}`
      );
      return [...alreadyAvailableDetails, ...response.data.data];
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Add New Warranty Detail
export const addWarrantyDetailActionTable = createAsyncThunk(
  "warrantyDetailsTable/addWarrantyDetailTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi(`/product-warranty-details/create`, data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Update Warranty Expiry Date
export const updateWarrantyExpiryDateActionTable = createAsyncThunk(
  "warrantyDetailsTable/updateWarrantyExpiryDateTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await putApi(
        `/product-warranty-details/update-expiry-date`,
        data
      );
      return { response: response.data.data, updatedData: data };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Delete Warranty Detail
export const deleteWarrantyDetailActionTable = createAsyncThunk(
  "warrantyDetailsTable/deleteWarrantyDetailTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteApiWithData(
        `/product-warranty-details`,
        data
      );
      return { response: response.data, id: data?.warrantyId };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
