import { createSlice } from "@reduxjs/toolkit";
import { getDefaultMonthActionTable } from "redux/action/defaultMonthActions";
import { toastText } from "utils/utils";

const initialState: any = {
  data: null,
  fistTimeFetchLoading: true,
  total: 0,
  isLoading: false,
  count: 0,
  error: null,
};

const warrantyRulesTableSlice = createSlice({
  name: "getDefaultMonth",
  initialState,
  reducers: {
    clearRedux: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDefaultMonthActionTable.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getDefaultMonthActionTable.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.count = action?.payload?.total;
      state.isLoading = false;
      state.fistTimeFetchLoading = false;
    });
    builder.addCase(
      getDefaultMonthActionTable.rejected,
      (state, action: any) => {
        state.error = action.payload;
        console.log(action);
        if (action?.payload?.responseStatus !== 401) {
          toastText(action?.payload?.message, "error");
        }
        state.isLoading = false;
        state.fistTimeFetchLoading = false;
      }
    );
  },
});

export default warrantyRulesTableSlice;
export const { clearRedux } = warrantyRulesTableSlice.actions;
