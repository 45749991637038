import {
  SearchOutlined,
  // CloseCircleOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip, DatePicker } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { SearchAndFilterProps } from "./types";
// import { SEARCHABLE_FIELDS } from "../../../../utils/shipment";

const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
  const {
    performSearchHandler,
    searchValue,
    // searchFieldsSelectHandler,
    // resetButtonHandler,
    searchFields,
    onBulkUpdateDate,
    onMarkAsShipped,
    disableMarkAsShipped,
    isServiceCenter,
    selectedRowKeys,
  } = props;

  const [selectedDate, setSelectedDate] = useState<any>(null);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    onBulkUpdateDate(date ? date.format("YYYY-MM-DD") : null);
  };

  useEffect(() => {
    if (!selectedRowKeys.length) {
      setSelectedDate(null);
    }
  }, [selectedRowKeys]);
  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[8, 8]} align="middle" justify="start">
          <Col>
            <Input
              className={styles["search-filter__search"]}
              placeholder="Search..."
              suffix={<SearchOutlined />}
              onChange={performSearchHandler}
              value={searchValue}
              size="large"
              disabled={searchFields.length === 0}
            />
          </Col>

          {/* <Col>
            <Select
              // className={styles["search-filter__select"]}
              mode="multiple"
              maxTagCount="responsive"
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  title={omittedValues.map(({ label }) => label).join(", ")}
                >
                  <span>+{omittedValues?.length} more..</span>
                </Tooltip>
              )}
              allowClear
              placeholder="Search on fields"
              onChange={searchFieldsSelectHandler}
              options={SEARCHABLE_FIELDS}
              value={searchFields}
              style={{ width: "28rem" }}
            />
          </Col>

          <Col>
            <Tooltip title="Reset" placement="top">
              <Button
                type="primary"
                className={styles["search-filter__reset"]}
                onClick={resetButtonHandler}
              >
                <CloseCircleOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Tooltip>
          </Col> */}

          {!isServiceCenter && selectedRowKeys.length > 0 &&(
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col>
                <p>Bulk Update Delivery Date :</p>
              </Col>
              <Col>
                <DatePicker
                  className={styles["search-filter__date-picker"]}
                  placeholder="Delivery Date"
                  size="large"
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ width: "18rem" }}
                  format="MM/DD/YYYY"
                  disabled={isServiceCenter}
                />
              </Col>

              {/* Mark as Shipped Button */}
              <Col>
                <Tooltip title="Mark As Shipped" placement="top">
                  <Button
                    type="primary"
                    className={styles["search-filter__markReceived"]}
                    onClick={() => {
                      onMarkAsShipped();
                    }}
                    disabled={disableMarkAsShipped}
                  >
                    <CarryOutOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </Tooltip>
              </Col>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
