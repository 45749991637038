import { SideDrawerWrapper, TableActionHeader } from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { userColumns } from "constants/Data";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteApi, getApi } from "redux/apis";
import { AddSvg } from "utils/svgs";
import { toastText } from "utils/utils";
import AddServiceCenterBody from "./AddServiceCenterBody";
import DynamicTable from "./Table";
import styles from "./index.module.scss";

const ServiceCenterTable = () => {
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [PageSize, setPageSize] = useState(
    Number(localStorage.getItem("userTableSize")) || 10
  );
  const [totalRecords, setTotalRecords] = useState(0);

  const [filterValue, setFilterValue] = useState("all");
  const [drawerInfo, setDrawerInfo] = useState({
    drawerTitle: "Users",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seletcedServiceCenter, setSelectedServiceCenter] = useState<any>();
  const [formateData, setFormateData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [viewMode, setViewMode] = useState(false); // Track view mode
  // Remove the side drawer
  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
  };

  // Open side drawer with animation
  const openDrawerHandler = () => {
    setDrawerInfo({ drawerTitle: "Add Service Center" });
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
    setViewMode(false);
  };

  // Close the side drawer with animation
  const closeDrawerByAnimation = () => {
    setSelectedServiceCenter(undefined);
    setDrawerAnimation(false);
    setViewMode(false);
  };

  // Handle search operation
  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  // Handle sorting operation
  const performSortHandler = (type: string) => {
    if (type !== sortOrder) {
      setSortOrder(type);
      setSortOrder(type === "ascend" ? "asc" : type ? "desc" : "");
      setSortField("name");
    }
  };

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  const navigate = useNavigate();
  useEffect(() => {
    if (roleName === "Employee") {
      navigate("/");
    }
  }, []);

  // Handle pagination for the table
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  // Modify the page size
  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);
      localStorage.setItem("userTableSize", size.toString());
    }
    setCurrentPage(1);
  };

  // Open the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Change data and title between components
  const setDrawerInfoHandler = (drawerTitle: any) => {
    setDrawerInfo({ drawerTitle });
  };

  // Confirm operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Delete user
  const deleteHandler = async () => {
    if (seletcedServiceCenter) {
      try {
        setIsDeleteLoading(true);
        const response = await deleteApi(
          `/serviceCenter/delete?id=${seletcedServiceCenter.id}`
        );
        const message =
          response?.data?.message || "Service Center deleted successfully";
        toastText(message, "success");
      } catch (error : any) {
        const message =
          error.response?.data?.error ||
          "Something went wrong in creating employee salary";
        toastText(message, "error");
      
      } finally {
        setIsDeleteLoading(false);
        setIsModalOpen(false);
        setSelectedServiceCenter(undefined);
        fetchServiceCenter();
      }
    }
  };

  const fetchServiceCenter = async () => {
    try {
      setIsLoading(true);

      const query: any = {
        page: currentPage,
        search: searchValue,
        sortBy: sortField,
        sortOrder: sortOrder,
        pageSize: PageSize,
        filter: filterValue === "active",
      };

      if (filterValue === "all") {
        delete query.filter;
      }

      const users = await getApi("/serviceCenter", query);

      setFormateData(users.data.data);
      setTotalRecords(users.data.total);
    } catch (err: any) {
      if (err.response?.data?.error?.code !== 103) {
        toastText("Something went wrong in fetching service centers", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchServiceCenter();
  }, [PageSize, searchValue, currentPage, sortOrder, sortField, filterValue]);

  return (
    <>
      <div className={styles["user-table"]}>
        <div>
          <TableActionHeader title={"Service Centers"}>
            <div className={styles["user-table__action"]}>
              <button
                className={`btn-blue ${styles["user-table__action--button"]}`}
                onClick={openDrawerHandler}
              >
                <AddSvg />
                <p>Add Service Center</p>
              </button>
            </div>
          </TableActionHeader>
          <div>
            <DynamicTable
              userDataSource={formateData}
              userColumns={userColumns}
              paginationChangeHandler={paginationChangeHandler}
              currentPage={currentPage}
              totalRecords={totalRecords}
              performSearchHandler={performSearchHandler}
              searchValue={searchValue}
              showModal={showModal}
              openDrawerHandler={openDrawerHandler}
              setDrawerInfoHandler={setDrawerInfoHandler}
              setSelectedServiceCenter={setSelectedServiceCenter}
              performSortHandler={(type: any) => performSortHandler(type)}
              performFilterHandler={(value) => setFilterValue(value)}
              filterValue={filterValue}
              PageSize={PageSize}
              modifyPageSize={modifyPageSize}
              sortDirection={sortOrder}
              isLoading={isLoading}
              setIsViewMode={setViewMode}
              fetchServiceCenter={fetchServiceCenter}
            />
          </div>
        </div>
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
        isAddUserLoading={isDeleteLoading}
      />
      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={() => {
            closeDrawerByAnimation();
          }}
          // closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          <AddServiceCenterBody
            closeDrawerByAnimation={closeDrawerByAnimation}
            seletcedServiceCenter={seletcedServiceCenter}
            setSeletcedServiceCenter={setSelectedServiceCenter}
            fetchServiceCenter={fetchServiceCenter}
            isViewMode={viewMode}
          />
        </SideDrawerWrapper>
      )}
    </>
  );
};

export default ServiceCenterTable;
