import {
  Loader,
  SideDrawerWrapper,
  TableActionHeader,
} from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { userColumns } from "constants/Data";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserAction, getUsersAction } from "redux/action/userAction";
import { AppDispatch } from "redux/store";
import { AddSvg } from "utils/svgs";
import AddUserBody from "./AddUserBody";
import DynamicTable from "./Table";
import styles from "./index.module.scss";

// UsersTable component
const UsersTable = () => {
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [PageSize, setPageSize] = useState(
    Number(localStorage.getItem("userTableSize")) || 10
  );
  const [filterValue, setFilterValue] = useState("all");
  const [type, setType] = useState("asc");
  const [drawerInfo, setDrawerInfo] = useState({
    drawerTitle: "Users",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSelectedUser, setEditSelectedUser] = useState<any>();
  const [formateData, setFormateData] = useState<any>([]);
  const [sortDirection, SetsortDirection] = useState("ascend");

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  // Selectors
  const { isLoading, fistTimeFetchLoading, isAddUserLoading } = useSelector(
    (state: any) => state?.users
  );

  const UserDataState = useSelector((state: any) => state?.users);
  const totalRecords = useSelector((state: any) => state?.users?.count);

  const profileData = useSelector((state: any) => state?.userProfile?.data);

  const loggedInRoleName = profileData?.companyRoles[0]?.role?.roleName;

  const isEmployee =
    profileData?.companyRoles[0]?.role?.roleName === "Employee";

  // Format user data
  useEffect(() => {
    if (UserDataState?.data?.length === 0 && currentPage > 1) {
      const query: any = {
        page: currentPage - 1,
        limit: PageSize,
        search: searchValue,
        filter: filterValue === "active",
        sort: type,
        type: sortDirection === "ascend" ? "asc" : "desc",
      };

      if (filterValue === "all") {
        delete query?.filter;
      }

      dispatch(getUsersAction(query));
      setCurrentPage(currentPage - 1);
    }

    const data = UserDataState?.data?.map((user: any) => {
      return {
        id: user?.id,
        userId: user?.userId,
        name:
          (user?.user?.firstName || "") + " " + (user?.user?.lastName || ""),
        email: user?.user?.email,
        phone: user?.user?.phone,
        simplePhone: user?.user?.phone,
        role: user?.role?.roleName,
        roleId: user?.roleId,
        status: user?.user?.status,
        isAdmin: user?.role?.isAdminRole,
        isCompanyAdmin: user?.role?.isCompanyAdmin,
        serviceCenters:
          user?.user?.serviceCenterAccess?.map((access: any) => ({
            serviceCenterId: access?.serviceCenterId,
            serviceCenterName: access?.serviceCenter?.name,
          })) || [],
      };
    });

    setFormateData(data);
  }, [UserDataState]);

  // Fetch user data
  const fetchUsers = () => {
    const query: any = {
      page: currentPage,
      limit: PageSize,
      search: searchValue,
      filter: filterValue === "active",
      sort: "firstName",
      type: type,
    };

    if (filterValue === "all") {
      delete query.filter;
    }

    dispatch(getUsersAction(query));
  };

  useEffect(() => {
    fetchUsers();
  }, [PageSize, currentPage, filterValue, searchValue, type]);

  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
  };

  const openDrawerHandler = () => {
    setDrawerInfo({ drawerTitle: "Add Users" });
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
  };

  const closeDrawerByAnimation = () => {
    setEditSelectedUser(undefined);
    setDrawerAnimation(false);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  const performSortHandler = (type: string) => {
    if (type !== sortDirection) {
      SetsortDirection(type);
      setType(type === "ascend" ? "asc" : "desc");
      setCurrentPage(1);
    }
  };

  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);
      localStorage.setItem("userTableSize", size.toString());
    }
    setCurrentPage(1);
  };

  // Open the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Change data and title between components
  const setDrawerInfoHandler = (drawerTitle: any) => {
    setDrawerInfo({ drawerTitle });
  };

  // Confirm operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Delete user
  const deleteHandler = () => {
    if (editSelectedUser) {
      dispatch(deleteUserAction(editSelectedUser)).then(() => {
        setIsModalOpen(false);
        setEditSelectedUser(undefined);
      });
    }
  };

  // JSX
  return (
    <>
      <div className={styles["user-table"]}>
        {!fistTimeFetchLoading ? (
          <div>
            <TableActionHeader title={"User"}>
              {!isEmployee && (
                <div className={styles["user-table__action"]}>
                  <button
                    className={`btn-blue ${styles["user-table__action--button"]}`}
                    onClick={openDrawerHandler}
                  >
                    <AddSvg />
                    <p>Add User</p>
                  </button>
                </div>
              )}
            </TableActionHeader>
            <div>
              <DynamicTable
                userDataSource={formateData}
                userColumns={userColumns}
                paginationChangeHandler={paginationChangeHandler}
                currentPage={currentPage}
                totalRecords={totalRecords}
                performSearchHandler={performSearchHandler}
                searchValue={searchValue}
                showModal={showModal}
                openDrawerHandler={openDrawerHandler}
                setDrawerInfoHandler={setDrawerInfoHandler}
                setEditSelectedUser={setEditSelectedUser}
                performSortHandler={(type: any) => performSortHandler(type)}
                performFilterHandler={(value) => setFilterValue(value)}
                filterValue={filterValue}
                PageSize={PageSize}
                modifyPageSize={modifyPageSize}
                sortDirection={sortDirection}
                isLoading={isLoading}
                isAddUserLoading={isAddUserLoading}
                loggedInRoleName={loggedInRoleName}
                profileData={profileData}
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
        // isLoading={isLoading}
        isAddUserLoading={isAddUserLoading}
      />
      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          <AddUserBody
            closeDrawerByAnimation={closeDrawerByAnimation}
            editSelectedUser={editSelectedUser}
            setEditSelectedUser={setEditSelectedUser}
            fetchUsers={fetchUsers}
          />
        </SideDrawerWrapper>
      )}
    </>
  );
};

export default UsersTable;
