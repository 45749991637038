import { createAsyncThunk } from "@reduxjs/toolkit";
import { postWithParamsApi } from "redux/apis";

export const qbdItemsAction = createAsyncThunk(
  "qbdItems",
  async (data: any, { rejectWithValue }) => {
    try {
      const params = {
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
      };
      const body = {
        fields: data.fields,
        searchValue: data.searchValue,
      };
      const response = await postWithParamsApi("/items/getItems", body, params);

      return response?.data?.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
