import "./index.scss";
import CompletedSalesOrder from "components/CompletedSalesOrder";

export const PackingLists = () => {
  return (
    <div>
      <CompletedSalesOrder />
    </div>
  );
};
