import { Modal, Button } from "antd";
import { FC } from "react";
import { WarningOutlined } from "@ant-design/icons";

interface Discrepancy {
  itemName: string;
  packed: number;
  expected: number;
  status: string;
}

interface PalletWarningModalProps {
  discrepancies: Discrepancy[];
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const PalletWarningModel: FC<PalletWarningModalProps> = ({
  discrepancies,
  isVisible,
  onConfirm,
  onCancel,
}) => {
  const formatDiscrepancy = (discrepancy: Discrepancy) => {
    const { itemName, packed, expected, status } = discrepancy;
    const isOverPacked = status === "Over-Packed";
    const isUnderPacked = status === "Under-Packed";

    return (
      <p style={{fontSize:'15px'}}>
        <span>Item {itemName}</span>:{" "}
        <span
          style={{
            fontWeight: "bold",
            color: isOverPacked ? "#d32f2f" : isUnderPacked ? "#d32f2f" : "#4caf50",
          }}
        >
          Packed {packed}
        </span>
        ,{" "}
        <span
          style={{
            fontWeight: "bold",
            color: "#4caf50",
          }}
        >
          Expected {expected}
        </span>{" "}
        ({status})
      </p>
    );
  };

  return (
    <Modal
      title={
        <div
          style={{
            color: "#d32f2f",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <WarningOutlined style={{ fontSize: "24px" }} /> Discrepancies Detected
        </div>
      }
      open={isVisible}
      footer={null}
      onCancel={onCancel}
      width={700}
    >
      <div
        style={{
          color: "#333",
          fontFamily: "Arial, sans-serif",
          lineHeight: "1.6",
        }}
      >
         <p
          style={{
            fontSize: "16px",
            marginBottom: "15px",
            fontWeight: "500",
          }}
        >
          The following items do not match the Sales Order. Proceed with saving
          the Packing Slip despite discrepancies?
        </p>
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: "0",
            fontSize: 16,
            marginTop: "20px",
          }}
        >
          {discrepancies.map((discrepancy, index) => (
            <li
              key={index}
              style={{
                marginBottom: "8px",
                padding: "5px",
                borderRadius: "4px",
                color: "#333",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginRight: "10px",
                  fontWeight: "bold",
                }}
              >
                •
              </span>
              <p style={{ fontSize: "15px", margin: 0 }}>
                {formatDiscrepancy(discrepancy)}
              </p>
            </li>
          ))}
        </ul>
        <div
          style={{
            display: "flex",
            marginTop: "40px",
            gap: "15px",
          }}
        >
          <Button
            size="large"
            style={{
              backgroundColor: "#f5f5f5",
              borderColor: "#ccc",
              color: "#333",
            }}
            onClick={onCancel}
            className="cancel-button"
            autoFocus
          >
            Continue Editing
          </Button>
          <Button
            type="primary"
            onClick={onConfirm}
            size="large"
            style={{
              backgroundColor: "#4caf50",
              borderColor: "#4caf50",
              color: "#fff",
            }}
            className="save-button"
          >
            Save & Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PalletWarningModel;
