// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import ServiceCenterTable from "components/settings/ServiceCenter";
// import { toastText } from "utils/utils";

export default function ServiceCenter() {
  // const navigate = useNavigate();
  // const profileData = useSelector((state: any) => state?.userProfile?.data);
  // const userRole = profileData?.companyRoles[0]?.role?.roleName;

  // useEffect(() => {
  //   if (userRole !== "Company Admin") {
  //     toastText("You don't have permission to access this page", "error");
  //     navigate("/dashboard");
  //   }
  // }, [userRole, navigate]);

  // // Only render if user is admin
  // if (userRole !== "Company Admin") {
  //   return null;
  // }
  return (
    <div>
      <ServiceCenterTable />
    </div>
  );
}
