import { Button, Col, Modal, Row, Table, Tooltip } from "antd";
import SalesOrder from "components/SalesOrder";
import { FC, useState } from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { invalidText } from "utils/utils";
import PalletWarningModel from "../PalletWarning";

const SearchAndFilter: FC<any> = (props) => {
  const {
    onOrderClick,
    isOrderStatusModalOpen,
    onOrderStatusModalClose,
    onClick,
    onSaveAndDraft,
    packageId,
    palletsLength,
    // handlePackingList,
    isSaveAndDraftLoading,
    finalItemStatus,
    isCreatePackingLoading,
    discrepancies,
    isDiscrepancyModalOpen,
    handleCreatePackingList,
    handleConfirmDiscrepancy,
    handleCancelDiscrepancy,
    fetchItems,
  } = props;

  const [isAddSalesOrderModalOpen, setIsAddSalesOrderModalOpen] =
    useState(false);

  // Construct the display string for sales orders with max 2 names

  const handleCancel = () => {
    setIsAddSalesOrderModalOpen(false);
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "itemName",
      key: "itemName",
      width: "20%",
    },
    {
      title: "SO Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      width: "20%",
    },
    {
      title: "Remaining",
      key: "remaining",
      render: (_: any, record: any) => {
        const remaining = record.totalQuantity - record.totalPalletQuantity;
        return remaining > 0 ? remaining : 0;
      },
      width: "20%",
    },
    {
      title: "Pallets",
      dataIndex: "palletNames",
      key: "palletNames",
      width: "20%",
      render: (text: string) => (invalidText(text) ? "-" : text),
    },
    {
      title: "Status",
      key: "status",
      render: (text: any, record: any) => {
        const remaining = record.totalQuantity - record.totalPalletQuantity;
        const status = remaining <= 0 ? "Completed" : "Not Completed";

        // Conditional text color
        const textColor = status === "Completed" ? "green" : "red";

        return <span style={{ color: textColor }}>{status}</span>;
      },
      width: "20%",
    },
  ];

  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col>
            <Button
              type="primary"
              className={styles["search-filter__markReceived"]}
              onClick={onClick}
              disabled={isSaveAndDraftLoading || isCreatePackingLoading}
            >
              Add Pallet
            </Button>
          </Col>

          <Col>
            <Row gutter={[8, 8]} align="middle" justify="end">
              <Col>
                <Button
                  type="primary"
                  className={styles["search-filter__markReceived"]}
                  onClick={onOrderClick}
                  disabled={isSaveAndDraftLoading || isCreatePackingLoading}
                >
                  View Status
                </Button>
              </Col>
              <Col>
                <Tooltip title={"Add new Sales order to this shipping"}>
                  <Button
                    type="primary"
                    className={styles["search-filter__markReceived"]}
                    onClick={() => {
                      setIsAddSalesOrderModalOpen(true);
                    }}
                    disabled={isSaveAndDraftLoading || isCreatePackingLoading}
                  >
                    Add Sales Order
                  </Button>
                </Tooltip>
              </Col>
              {palletsLength > 0 && (
                <>
                  <Col>
                    <Button
                      type="primary"
                      className={styles["search-filter__markReceived"]}
                      onClick={onSaveAndDraft}
                      disabled={isSaveAndDraftLoading || isCreatePackingLoading}
                    >
                      Save and Draft
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      type="primary"
                      className={styles["search-filter__markReceived"]}
                      onClick={handleCreatePackingList}
                      disabled={isSaveAndDraftLoading || isCreatePackingLoading}
                    >
                      Create Packing List
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </div>

      <Modal
        title="Sales Order Status"
        open={isOrderStatusModalOpen}
        onCancel={onOrderStatusModalClose}
        footer={null}
        width={800}
      >
        <div>
          <Table
            columns={columns}
            dataSource={finalItemStatus}
            rowKey="itemId"
            pagination={false}
          />
        </div>
      </Modal>

      <Modal
        title="Add sales orders to an existing shippings"
        open={isAddSalesOrderModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <div>
          <SalesOrder
            packageId={packageId}
            isDefaultCustomer={true}
            handleCancel={handleCancel}
            fetchItems={fetchItems}
          />
        </div>
      </Modal>
      <PalletWarningModel
        discrepancies={discrepancies}
        isVisible={isDiscrepancyModalOpen}
        onConfirm={handleConfirmDiscrepancy}
        onCancel={handleCancelDiscrepancy}
      />
    </div>
  );
};

export default SearchAndFilter;
