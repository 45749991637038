import { Space, Table, Tag, Popover } from "antd";
import { EditActionSvg, SortSvgBottom, SortSvgTop } from "utils/svgs";
import { pageSizeOptionsPaging } from "utils/constant";
import moment from "moment";
import styles from "./index.module.scss";
import SearchAndFilter from "../SearchAndFilter";
import { invalidText } from "utils/utils";

const { Column } = Table;

interface Props {
  allInventoryData: any[];
  isLoading: boolean;
  onEdit: (inventoryData: any) => void;
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  tableChangeHandler: (pagination: any, filters: any, sorter: any) => void;
  isServiceCenter: boolean;
  isEmployee: boolean;
  searchValue: string;
  performSearchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchFields: string[];
  handleOk: any;
  handleServiceCenterChange: any;
  selectedServiceCenter: any;
  handleSparePartsChange: any;
  selectedSpareParts: any;
  fetchInventory: any;
  handleFromDateChange: any;
  handleToDateChange: any;
  fromDate: any;
  toDate: any;
  handleSalesOrderNumberChange: any;
  selectedSalesOrderNumbers: any;
  isAdmin?: any;
  sortDirection: any;
}

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const InventoryTable = (props: Props) => {
  const {
    allInventoryData,
    isLoading,
    onEdit,
    currentPage,
    pageSize,
    totalRecords,
    tableChangeHandler,
    isServiceCenter,
    isEmployee,
    searchValue,
    performSearchHandler,
    searchFields,
    handleOk,
    handleServiceCenterChange,
    selectedServiceCenter,
    handleSparePartsChange,
    selectedSpareParts,
    handleFromDateChange,
    handleToDateChange,
    fromDate,
    toDate,
    fetchInventory,
    handleSalesOrderNumberChange,
    selectedSalesOrderNumbers,
    isAdmin,
    sortDirection,
  } = props;

  console.log(isEmployee);
  return (
    <div className={styles["inventory-table__wrapper"]}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        searchFields={searchFields}
        isServiceCenter={isServiceCenter}
        handleServiceCenterChange={handleServiceCenterChange}
        selectedServiceCenter={selectedServiceCenter}
        handleSparePartsChange={handleSparePartsChange}
        selectedSpareParts={selectedSpareParts}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        onApply={handleOk}
        fromDate={fromDate}
        toDate={toDate}
        handleSalesOrderNumberChange={handleSalesOrderNumberChange}
        selectedSalesOrderNumbers={selectedSalesOrderNumbers}
        fetchInventory={fetchInventory}
      />
      <Table
        dataSource={allInventoryData}
        rowKey={(record) => record.id}
        loading={isLoading}
        scroll={{ y: "calc(100vh - 450px)", x: "63vh" }}
        onChange={tableChangeHandler}
        pagination={{
          total: totalRecords,
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
        }}
        className={styles["inventory-table"]}
      >
        <Column
          title="#"
          key="serialNumber"
          width={"9%"}
          render={(_: any, __: any, index: number) =>
            (currentPage - 1) * pageSize + index + 1
          }
        />
        <Column
          title="Service Center"
          dataIndex="serviceCenter"
          key="serviceCenter"
          className="bg-white"
          width={"16%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) => {
            if (text && text.length > 11) {
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 11)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="3nStar Sales Order"
          dataIndex="salesOrderNumber"
          key="salesOrderNumber"
          className="bg-white"
          width={"20%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Item#"
          dataIndex="itemNumber"
          key="itemNumber"
          className="bg-white"
          width={"19%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Item Description"
          dataIndex="itemDescription"
          key="itemDescription"
          className="bg-white"
          width={"20%"}
          render={(text: string) => {
            if (invalidText(text)) return "-";
            if (text && text.length > 20) {
              // Adjust the length threshold as needed
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 20)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="Arrival Date"
          dataIndex="arrivalDate"
          key="arrivalDate"
          className="bg-white"
          width={"15%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text) => (text ? moment(text).format("MM/DD/YYYY") : "-")}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"15%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(status: string) => {
            const statusColorMap: Record<string, string> = {
              IN_TRANSIT: "blue",
              RECEIVED: "green",
              LOST: "yellow",
              DAMAGED: "red",
            };
            const formattedStatus = status
              .replace(/_/g, " ") // Replaces underscores with spaces
              .toLowerCase() // Converts the string to lowercase
              .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizes the first letter of each word

            return (
              <Tag color={statusColorMap[status] || "default"}>
                {formattedStatus}
              </Tag>
            );
          }}
        />
        <Column
          title="Actions"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"10%"}
          render={(values: any, data: any) => {
            console.log("data: ", data);
            const isEditable =
              !isEmployee && (isAdmin || data.status === "IN_TRANSIT");

            // const isEditable = isAdmin || data.status === "IN_TRANSIT";
            return (
              <Space size={20}>
                {isEditable ? (
                  <div
                    className="cursor-pointer flex align-center justify-center"
                    onClick={() => onEdit(data)}
                    style={{ marginRight: "2rem" }}
                  >
                    <EditActionSvg />
                  </div>
                ) : (
                  <div
                    className="flex align-center justify-center"
                    style={{
                      marginRight: "2rem",
                      opacity: 0.3, // Makes the icon appear disabled
                      cursor: "not-allowed",
                    }}
                  >
                    <EditActionSvg />
                  </div>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default InventoryTable;
