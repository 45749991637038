import toast from "react-hot-toast";

export const toastText = (message: string, type: string) => {
  // Common style configuration for both success and error toasts
  const commonStyle = {
    style: {
      fontSize: "16px",
    },
  };

  switch (type) {
    case "success":
      toast.success(message, commonStyle);
      break;

    case "error":
      toast.error(message, commonStyle);
      break;
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // Remove all non-numeric characters from the input
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Define the phone number format (e.g., "(XXX) XXX-XXXX")
  const format = "($1) $2-$3";

  // Apply the mask to the cleaned number using a regular expression
  const maskedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, format);

  return maskedNumber;
};
//The list of all available permissions
export const getPermissionObject = (
  permissionObj: any,
  allPermissions: any
) => {
  let formattedArray: any = [];
  const updatedAllPermission = allPermissions.map((singlePermission: any) => {
    return {
      ...singlePermission,
      isBold: false,
    };
  });

  for (const singlePermissionObj of permissionObj) {
    let tempArray = [];
    tempArray.push({ permissionName: singlePermissionObj.name, isBold: true });
    const filteredPermissions = updatedAllPermission.filter(
      (singlePermission: any) => {
        return singlePermissionObj.items.includes(singlePermission.sortId);
      }
    );
    tempArray = [...tempArray, ...filteredPermissions];
    formattedArray = [...formattedArray, ...tempArray];
  }

  return formattedArray;
};

//Check if a user has a required permission.
// export const checkPermission = (
//   allPermissions: any,
//   requiredPermission: any
// ) => {
//   if (!allPermissions) {
//     return false;
//   }
//   const permissionsList = allPermissions;
//   const permission = permissionsList?.find(
//     (singlePermission: any) =>
//       singlePermission.permissionName === requiredPermission.permissionName
//   );
//   if (permission) {
//     const permitted = requiredPermission.permission.some(
//       (singlePermission: string) => permission[singlePermission]
//     );
//     return permitted || permission["all"];
//   } else {
//     return false;
//   }
// };

export function exportXmlFile(xmlString: string, filename: string) {
  // Create a Blob from the XML string
  const blob = new Blob([xmlString], { type: "application/xml" });

  // Create a download link for the Blob
  const downloadLink = document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = `${filename}.qwc` || "data.qwc"; // Specify the filename, 'data.xml' by default

  // Trigger a click event to download the file
  downloadLink.click();
}

// TypeScript annotation for UUID function
export const UUID = () => (Math.random() + 1).toString(36).substring(2);

export function invalidText(value: string | number | null | undefined) {
  return (
    value == null || value == undefined || value.toString().trim().length == 0
  );
}

export function capitalizeFirstLetter(string:string) {
  if (!string) return ""; // Handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}
