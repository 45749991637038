/* eslint-disable react-hooks/rules-of-hooks */
import { Space, Switch, Table } from "antd";
import { FC } from "react";
import {
  DeleteActionSvg,
  EditActionSvg,
  SortSvgBottom,
  SortSvgTop,
} from "utils/svgs";
import SearchAndFilter from "../SearchAndFilter/index";
import "./index.scss";
import { DynamicTableProps } from "./types";

import { pageSizeOptionsPaging } from "utils/constant";
import { putApi } from "redux/apis";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { toastText } from "utils/utils";

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const DynamicTable: FC<DynamicTableProps> = (props) => {
  const { Column } = Table;
  const {
    userDataSource,
    performSearchHandler,
    performFilterHandler,
    searchValue,
    filterValue,
    showModal,
    openDrawerHandler,
    setDrawerInfoHandler,
    setSelectedServiceCenter,
    performSortHandler,
    totalRecords,
    currentPage,
    PageSize,
    modifyPageSize,
    paginationChangeHandler,
    sortDirection,
    setIsViewMode,
    isLoading,
    fetchServiceCenter
  } = props;

  // Edit data handler
  const editDataHandler = (userObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("Edit Service Center");
    setSelectedServiceCenter(userObject);
  };

  const viewDataHandler = (userObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("View Service Center"); // Change title
    setSelectedServiceCenter(userObject);
    setIsViewMode(true); // Set viewMode to true
  };

  // Delete user data handler
  const deleteDataHandler = (userObject: any) => {
    setSelectedServiceCenter(userObject);
  };

  // Handle user status change
  const statusHandler = async (value: any, data: any) => {
    const finalData: any = {
      status: value,
      id: data?.id,
    };
    try {
      const response = await putApi("/serviceCenter/status", finalData);
      const message =
        response?.data?.message || "Service center status updated successfully";
        toastText(message, "success");
        fetchServiceCenter();
    } catch (error) {
      // Handle errors here
      console.error("An error occurred:", error);
    }
  };

  // For handle the table change click
  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, pageInfo.current);
  };

  // JSX
  return (
    <div className={"dynamic-table"}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        performFilterHandler={performFilterHandler}
        filterValue={filterValue}
        PageSize={PageSize}
      />
      <Table
        dataSource={userDataSource}
        pagination={{
          total: totalRecords,
          current: currentPage,
          onChange: paginationChangeHandler,
          pageSize: PageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
          onShowSizeChange: modifyPageSize,
        }}
        loading={isLoading}
        className="table-global"
        onChange={tableChangeHandler}
        scroll={{ y: "calc(100vh - 450px)", x: "63vh" }}
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          className="bg-white"
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Created By"
          dataIndex="createdBy"
          key="createdBy"
          className="bg-white"
        />
        <Column
          title="Modified At"
          dataIndex="modifiedAt"
          key="modifiedAt"
          className="bg-white"
          render={(value: any) => {
            // Format the date to MM-DD-YYYY
            return moment(value).format("MM/DD/YYYY, hh:mma");
          }}
        />

        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"10%"}
          render={(value: any, data: any) => {
            return (
              <Switch
                key={Math.random()}
                defaultChecked={value}
                // disabled={data?.isCompanyAdmin}
                onChange={(e) => statusHandler(e, data)}
              ></Switch>
            );
          }}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"15%"}
          render={(values: any, data: any) => {
            return (
              <Space size={20}>
                {!(data.isCompanyAdmin || data.isAdmin) ? (
                  <>
                    <div
                      className="cursor-pointer flex align-center justify-center"
                      onClick={() => editDataHandler(data)}
                      style={{ marginRight: "2rem" }}
                    >
                      <EditActionSvg />
                    </div>
                    <div
                      style={{ marginRight: "2rem" }}
                      className="cursor-pointer flex align-center justify-center"
                      onClick={() => {
                        deleteDataHandler(data);
                        showModal();
                      }}
                    >
                      <DeleteActionSvg />
                    </div>
                    <div
                      style={{ marginRight: "2rem" }}
                      className="cursor-pointer flex align-center justify-center"
                      onClick={() => {
                        viewDataHandler(data);
                      }}
                    >
                      <EyeOutlined style={{ fontSize: "20px" }} />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{ marginRight: "2rem" }}
                      className={"dynamic-table__no-action"}
                    >
                      <EditActionSvg />
                    </div>
                    <div
                      style={{ marginRight: "2rem" }}
                      className={"dynamic-table__no-action"}
                    >
                      <DeleteActionSvg />
                    </div>
                    <div
                      style={{ marginRight: "2rem" }}
                      className={"dynamic-table__no-action"}
                    >
                      <EyeOutlined />
                    </div>
                  </>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default DynamicTable;
