import { TableActionHeader } from "components/Global";
import DraftShippingList from "./DraftShippingList";
// import ShippingTable from "./ShippingTable";

const ShippingList = () => {
  return (
    <>
      <div>
        <TableActionHeader title={"Shipping"}></TableActionHeader>
        <DraftShippingList />
        {/* <ShippingTable /> */}
      </div>
    </>
  );
};

export default ShippingList;
